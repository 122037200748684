import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom'
import { Loader, Dimmer, Sticky, Grid, Radio, Card, Progress, Label, Container, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'

function Classes() {
    const user = useStoreState( state => state.user )

    return <React.Fragment>
                <Divider horizontal>
                    Mes Classes
                </Divider>
                <ListeClasses />
                { user.username !== 'test_prof' &&
                    <React.Fragment>
                        <Divider horizontal>
                            Ajouter une classe
                        </Divider>
                        { user.enseignant && <AjouteClasse /> }
                    </React.Fragment> }
            </React.Fragment>;
}

function ListeClasses() {
    const classes = useStoreState( state => state.classes );
    const classesList = Object.values(classes);
    const fetchDepotCopie = useStoreActions(actions => actions.fetchDepotCopie);
    const fetchClasses = useStoreActions(actions => actions.fetchClasses);
    const fetchClasse = useStoreActions(actions => actions.fetchClasse);
    const fetchCopieEleve = useStoreActions(actions => actions.fetchCopieEleve);
    const user = useStoreState( state => state.user )

    useEffect(() => {
        fetchClasses();
    }, [fetchClasses]);

    if (user.enseignant) {
        return <List size='large'>
            { classesList.map(classe =>
                <List.Item key={classe.id}>
                    <List.Content>
                        <List.Header>
                    <a href='#' onClick={ () => fetchClasse(classe.id) } >{ classe.nom }</a>
                        </List.Header>
                    </List.Content>
                </List.Item>
            )}
            </List>
    } else {
        return <List>
            { classesList.map(classe =>
                <List.Item key={classe.id}>
                    { classe.nom }
                    <List>
                        { classe.sujets.map( sujet => 
                            sujet.visible &&
                                <List.Item key={sujet.id}>
                                    { sujet.nom }
                                    { sujet.correctionVisible && sujet.copie &&
                                        <Button onClick={ () => fetchCopieEleve(sujet.copie) }>Voir ma copie</Button>
                                    }
                                    { 
                                        sujet.depotCopies && 
                                        !sujet.copie_corrigee &&
                                            <Button onClick={ () => fetchDepotCopie({ sujetId: sujet.id }) } >
                                                { sujet.copie ? 'Éditer ma copie' : 'Déposer une copie' }
                                            </Button> 
                                    }
                                </List.Item>
                        )}
                    </List>
                </List.Item>
            )}
        </List>
    }
}

function AjouteClasse() {
    const [nom,setNom] = useState('');
    const [lycee,setLycee] = useState('');

    const addClasse = useStoreActions(actions => actions.addClasse);

    const handleAjouteClick = () => {
        addClasse({ nom, lycee });
        setNom('');
        setLycee('');
    }
    const handleNomChange = e => setNom(e.target.value);
    const handleLyceeChange = e => setLycee(e.target.value);

    return <Form>
        <Form.Field>
            <label>Nom</label>
            <input placeholder='Classe' 
                value={ nom } onChange={handleNomChange}
            />
        </Form.Field>
        <Form.Field>
            <label>Lycée</label>
            <input placeholder='Lycée'
                value={ lycee } onChange={handleLyceeChange}
            />
        </Form.Field>
        <Button type='submit' onClick={ handleAjouteClick } >Ajouter une classe</Button>
    </Form>;
}

export default Classes;
