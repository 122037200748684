import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link, BrowserRouter } from 'react-router-dom'


// import { Provider, connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import store from './store';
// import { getClasse, getClassesError, getClasses, getClassesPending } from './reducers';
// import { API } from './actions';

import Webcam from "./components/camera"

import Copie from './components/copie';
//import CopieEleve from './components/copie-eleve';
import Classe from './components/classe';
import Classes from './components/classes';
import Sujet from './components/sujet';
//import SujetDepot from './components/sujet-depot';
import SujetRapport from './components/sujet-rapport';
import Dossiers from './components/dossiers';
import { Checkbox, Message, Label, Dropdown, Menu, Container, Breadcrumb, Loader, Dimmer, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'

function Navigation() {
    const classes = useStoreState( state => state.classes );
    const classe = useStoreState( state => state.classe );
    const sujet = useStoreState( state => state.sujet );
    const copie = useStoreState( state => state.copie );
    const depotCopie = useStoreState( state => state.depotCopie );
    const setClasse = useStoreActions(actions => actions.setClasse);
    const setSujet = useStoreActions(actions => actions.setSujet);
    const setCopie = useStoreActions(actions => actions.setCopie);
    const setDepotCopie = useStoreActions(actions => actions.setDepotCopie);
    
    const nombre_notes = sujet ? sujet.eleves.reduce( (total, eleve) => (eleve.note !== null ? 1 : 0) + total, 0 ) : null

    if (!classe) {
        return <Breadcrumb>
                <Breadcrumb.Section active>Classes</Breadcrumb.Section>
            </Breadcrumb>
    } else if (classe && !sujet) {
        return <Breadcrumb>
                <Breadcrumb.Section link onClick={ () => setClasse(null) } >Classes</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>{ classe.classe }</Breadcrumb.Section>
            </Breadcrumb>
    } else if (classe && sujet && !copie) {
        return <Breadcrumb>
                <Breadcrumb.Section link onClick={ () => { setClasse(null); setSujet(null) } } >
                    Classes
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link onClick={ () => setSujet(null) }>{ classe.classe }</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>{ sujet.sujet } [{ nombre_notes }/{ sujet.eleves.length }]</Breadcrumb.Section>
            </Breadcrumb>
    } else if (classe && sujet && copie) {
        return <Breadcrumb>
                <Breadcrumb.Section link onClick={ () => { setClasse(null); setSujet(null); setCopie(null); setDepotCopie(null) } } >
                    Classes
                </Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link onClick={ () => { setSujet(null); setCopie(null); setDepotCopie(null) } } >{ classe.classe.slice(0,4) }</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link onClick={ () => { setDepotCopie(null); setCopie(null) } }>{ sujet.sujet.slice(0,4) } [{ nombre_notes }/{ sujet.eleves.length }]</Breadcrumb.Section>
                <Breadcrumb.Divider />
                { depotCopie 
                        ? <Breadcrumb.Section link onClick={ () => { setDepotCopie(null) } }>{ copie.eleve.nom }</Breadcrumb.Section>
                        : <Breadcrumb.Section active>{ copie.eleve.nom }</Breadcrumb.Section> }
            </Breadcrumb>
    }
}

function Login() {
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const login = useStoreActions(state => state.login)
    const loginError = useStoreState(state => state.loginError)

    return <Container text>
        <Header as='h1'>Application de correction</Header>
        <Message>
            <Message.Header>Authentification</Message.Header>
            <p>
                Ce site nécessite de s'authentifier.
            </p>
        </Message>
        <Form
            onSubmit={ () => login({ username, password }) }
        >
            <Form.Field>
                <label>Nom d'utilisateur</label>
                <Form.Input type="text" value={ username } placeholder="Nom d'utilisateur" 
                    onChange={ (e, { value }) => setUsername(value) } />
            </Form.Field>
            <Form.Field>
                <label>Mot de passe</label>
                <Form.Input type="password" value={ password } placeholder="Password" 
                    onChange={ (e, { value }) => setPassword(value) } />
            </Form.Field>
            <Button type='submit' primary>Se connecter</Button>
	    { loginError && <Message negative>Erreur d'authentification. Vérifiez votre nom d'utilisateur et votre mot de passe.</Message> }
        </Form>
    </Container>
}

function HomeEleve() {
    const logout = useStoreActions(actions => actions.logout);
    const classes = useStoreState( state => state.classes );
    const classe = useStoreState( state => state.classe );
    const sujet = useStoreState( state => state.sujet );
    const copie = useStoreState( state => state.copie );
    const depotCopie = useStoreState( state => state.depotCopie );
    const user = useStoreState( state => state.user );
    const fetchClasses = useStoreActions(actions => actions.fetchClasses);
    const setDepotCopie = useStoreActions(actions => actions.setDepotCopie);
    const setCopie = useStoreActions(actions => actions.setCopie);

     useEffect(() => {
        fetchClasses();
    }, [fetchClasses]);

    return <Container fluid>
            <Menu fixed='top'>
                <Menu.Item>
                    <Label>E</Label>
                    { user.first_name }
                    { copie && <Button onClick={ () => setCopie(null) }>Retour</Button> }
                    { depotCopie && <Button onClick={ () => setDepotCopie(null) }>Retour</Button> }
                    <Button onClick={ () => logout() }>Logout</Button>
                </Menu.Item>
            </Menu>
            <Header as='h2'>Contenu</Header>
        
        </Container>;
}

function HomeEnseignant() {
    const logout = useStoreActions(actions => actions.logout);
    const fetchClasses = useStoreActions(actions => actions.fetchClasses);
    const classes = useStoreState( state => state.classes );
    const classe = useStoreState( state => state.classe );
    const sujet = useStoreState( state => state.sujet );
    const depotCopie = useStoreState( state => state.depotCopie );
    const copie = useStoreState( state => state.copie );
    const fetchCopie = useStoreActions( state => state.fetchCopie );
    const fetchDepotCopie = useStoreActions( state => state.fetchDepotCopie );
    const user = useStoreState( state => state.user );
    const compact = useStoreState( state => state.compact );
    const toggleCompact = useStoreActions( state => state.toggleCompact );

     useEffect(() => {
        fetchClasses();
    }, [fetchClasses]);

    function selectPrev() {
        var i = sujet.eleves.findIndex((e) => e.id == copie.eleve.id)
        if (i > 0)
            fetchCopie({ sujetId : sujet.id, eleveId : sujet.eleves[i-1].id })
    }
    function selectNext() {
        var i = sujet.eleves.findIndex((e) => e.id == copie.eleve.id)
        if (i < sujet.eleves.length - 1)
            fetchCopie({ sujetId : sujet.id, eleveId : sujet.eleves[i+1].id })
    }

    return <React.Fragment>
            <Menu fixed='top'>
                { copie && 
                    <Menu.Item>
                        <Button basic size='mini' compact
                        onClick={ selectPrev }
                        >
                        <Icon name='arrow left' /></Button>
                        <Button basic size='mini' compact
                        onClick={ selectNext }
                        >
                        <Icon name='arrow right' /></Button>

                    <Dropdown size='small' compact selection
                    onChange={ (e,{value}) => {
                        fetchCopie({ sujetId : sujet.id, eleveId : value })
                    } }
                        value={ copie.eleve.id }
                        options={ 
                            sujet.eleves.map( (e) =>
                                ({ key: e.id, value: e.id, text: e.nom })
                            )
                        } />
                                                <Label>{ Math.round(copie.note*100)/100 }</Label>
                        </Menu.Item>
                }
                <Menu.Item>
                    <Checkbox size='tiny'
                        checked={ compact }
                        onChange={ () => toggleCompact(!compact)  }
                        label='Compact' />
                </Menu.Item>
                <Menu.Item>
                    <Navigation />
                </Menu.Item>
                <Menu.Item>
                    <Label>Pr</Label>
                    { user.first_name }
                    <Button onClick={ () => logout() }>Logout</Button>
                </Menu.Item>
            </Menu>
            <Container fluid style={{ paddingTop : '68px' }}>
            { classes 
                  ? (classe
                      ? (sujet
                         ? (copie
                             ? <Copie />
                             : <Sujet />)
                         : <Classe />)
                      : <Classes />) : null }
            </Container>
        </React.Fragment>;
}

function Home() {
    const user = useStoreState( state => state.user );

    if (user.username === 'dossiers')
        return <Dossiers />
    else if (user.enseignant)
        return <HomeEnseignant />
    else
        return <HomeEleve />
}

function App() {
    const user = useStoreState( state => state.user );
    
    if (user === null) {
        return <Login />;
    }

   return <BrowserRouter>
           <Route path="/" exact component={Home} />
           <Route path="/sujet/:sujet_id/rapport" exact component={SujetRapport} />
    </BrowserRouter>;
}

export default App;
