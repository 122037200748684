import React, { createRef, useRef, useEffect, useState, Component } from 'react';
import { Popup, Placeholder, Menu, Rail, Image as SImage, Accordion, TextArea, Checkbox, Transition, Message, Dropdown, Loader, Dimmer, Sticky,Grid, Radio, Card, Progress, Input, Label, Container, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'
import {isBrowser} from 'react-device-detect';
import { useStoreActions, useStoreState } from 'easy-peasy';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Gluejar } from '@charliewilco/gluejar'
import service from '../api';

//import CopieImages from './copie-images';


const convertBlobUrlToBase64 = blobUrl => new Promise((resolve, reject) => {
    return fetch(blobUrl).then(response => response.blob())
        .then(response => {
            const reader = new FileReader();
            reader.readAsDataURL(response);

            reader.onload = () => resolve({
                fileName: blobUrl,
                base64: reader.result
            });
            reader.onerror = reject;
            return reader
        })
});

function Appreciation(props) {
    const [appreciation,setAppreciation] = useState(props.appreciation);

    useEffect(() => { 
            setAppreciation(props.appreciation); 
        }, 
        [ props.appreciation ]);

    return <React.Fragment>
            <Divider horizontal>Appréciation
                    { (appreciation != props.appreciation)
                       ? <Button primary onClick={ () => props.onChange(appreciation) }>Sauvegarder</Button>
                       : null }
                </Divider>
                <Form>
                <TextArea 
                    onChange={ (e,{value}) => setAppreciation(value) }
                    value={ appreciation || '' } />
            </Form>
        </React.Fragment>;
}

function QuestionForm(props) {
    const question = props.question

    const [nom,setNom] = useState(question.nom)
    const [bareme,setBareme] = useState(question.bareme)
    const [segments,setSegments] = useState(question.segments)
    const [description,setDescription] = useState(question.description)

    const [images, setImages] = useState([])

    if (!props.visible) return null;

    return <Segment color='grey'>
                <Form
                onSubmit={ () => props.onSubmit({ nom, bareme : parseFloat(bareme), 
                    segments : parseInt(segments), description, images }) }
                >
                    <Form.Field>
                        <label>Nom</label>
                        <Form.Input 
                            placeholder='Nom'
                            onChange={ (e, { value }) => setNom(value) }
                            value={ nom }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Bareme</label>
                        <Form.Input 
                            placeholder='Bareme'
                            onChange={ (e, { value }) => setBareme(value) }
                            value={ bareme }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Segments</label>
                        <Form.Input 
                            placeholder='Segments'
                            onChange={ (e, { value }) => setSegments(value) }
                            value={ segments }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Description</label>
                        <Form.TextArea 
                            placeholder='Description'
                            onChange={ (e, { value }) => setDescription(value) }
                            value={ description }
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Image</label>
                        <Gluejar onPaste={ files => setImages(files.images) }>
                            {({ images }) =>
                              images.length > 0 &&
                                images.map(image => 
                                <SImage fluid src={image} key={image} alt={`Pasted: ${image}`} />)
                            }
                          </Gluejar>
                    </Form.Field>
                    <Button type='submit' primary>Valider</Button>
                    <Button type='cancel'
                        onClick={ (e) => {
                            props.onCancel()
                            e.preventDefault()
                        } }
                    >
                        Annuler
                    </Button>
                </Form>
            </Segment>
}

function ReponseTypeForm(props) {
    const reponseType = props.responseType
    const visible = props.visible

    const [nom,setNom] = useState(reponseType ? reponseType.nom : '')
    const [action,setAction] = useState(reponseType ? reponseType.action : '')
    const [remed,setRemed] = useState(reponseType ? reponseType.texte : '')

    useEffect(() => {
        if (props.reponseType === null) {
            setNom('')
            setAction('')
            setRemed('')
        } else {
            setNom(props.reponseType.nom)
            setAction(props.reponseType.action)
            setRemed(props.reponseType.texte)
        }
    }, [ props.reponseType ])

    if (!visible) return null

    return  <Segment color='grey'>
                    <Header as='h3'>
                        { ((reponseType && reponseType.id)
                            ? 'Édition' : 'Ajout') + ' d\'une réponse type' }
                    </Header>
                        <Form onSubmit={ () => {
                            props.onSubmit({ nom, action, remed }) }
                        } >
                        <Form.Field>
                            <label>Nom</label>
                            <Form.Input
                                placeholder='Nom'
                                onChange={ (e, { value }) => setNom(value) }
                                value={ nom }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Action</label>
                            <Form.Input
                                placeholder='Action'
                                onChange={ (e, { value }) => setAction(value) }
                                value={ action }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Texte</label>
                            <TextArea
                                placeholder='Texte de remédiation'
                                onChange={ (e, { value }) => setRemed(value) }
                                value={ remed }
                            />
                        </Form.Field>
                        <Button type='submit' primary>Valider</Button>
                        <Button type='cancel'
                            onClick={ (e) => {
                                props.onCancel()
                                e.preventDefault()
                            } }
                        >
                            Annuler
                        </Button>
                    </Form>
                </Segment>
}

function Question(props) {
    const question = props.question
    const reponse = props.reponse
    const i = question.id
    const changeQuestion = useStoreActions( state => state.changeQuestion );
    const changeReponseType = useStoreActions( state => state.changeReponseType );

    const editReponseType = null
    const editQuestion = null
    const editQuestionNom = null
    const editQuestionSegments = null
    const editQuestionDescription = null
    const editQuestionBareme = null

    const [busy, setBusy] = useState(false)
    const [imagesShow, setImagesShow] = useState(props.imagesShow)
    useEffect(() => {
            setImagesShow(props.imagesShow)
    }, [props.imagesShow])
    
    const updateCopieSetReponse = useStoreActions( state => state.updateCopieSetReponse );

    const handleReponse = (reponseId, value) => {
        setBusy(true)
        updateCopieSetReponse({ reponseId, value }).then(() => setBusy(false))
        props.onAnswer()
    }

    const ref = useRef(null)

    useEffect(() => {
        if (props.focused) {
            ref.current.scrollIntoView({
                inline: 'nearest',
                block: 'center',
                behavior: 'smooth'
            })
        }
    }, [props.focused])

    const [questionFormOuverte, setQuestionFormOuverte] = useState(false)
    const handleQuestionFormSubmit = async ({ nom, bareme, description, segments, images }) => {
        const imagesBlobs = await Promise.all(images.map(convertBlobUrlToBase64))
        const images64 = imagesBlobs.map(image => image.base64)
        changeQuestion({ question, nom, bareme, 
            description, segments, images64 : (images64.length > 0) ? images64 : question.images64 })
        setQuestionFormOuverte(false)
    }

    const [reponseTypeFormOuverte, setReponseTypeFormOuverte] = useState(false)
    const [reponseTypeFormEdit, setReponseTypeFormEdit] = useState(null)

    const handleReponseTypeFormSubmit = ({ nom, action, remed }) => {
        changeReponseType({ question, nom, action, 
            texte: remed, reponseType: reponseTypeFormEdit })
        setReponseTypeFormOuverte(false)
    }
    
    const handleKeyEvent = (key, e) => {
        switch (key) {
            case 'x':
                return handleReponse(i, null)
            case '0': return handleReponse(i, 0)
            case '1': return handleReponse(i, 1)
            case '2': return handleReponse(i, 2)
            case '3': return handleReponse(i, 3)
            case '4': return handleReponse(i, 4)
            case '5': return handleReponse(i, 5)
            case '6': return handleReponse(i, 6)
            case '7': return handleReponse(i, 7)
            case '8': return handleReponse(i, 8)
            case '9': return handleReponse(i, 9)
        }
    }

    if (props.compact)
        return <React.Fragment>
                <Label color='red' size='tiny'>{ question.nom }</Label>
                <Button.Group horizontal='true'>
                <Button basic compact onClick={ (evt) => handleReponse(i, null) } 
                    positive={ !reponse.reponse }>X</Button>
                { Array(question.segments + 1).fill().map((_, j) => 
                    <Button basic compact key={ j } onClick={ (evt) => handleReponse(i, j) } 
                        positive={ (reponse.reponse && reponse.reponse.valeur === j) }
                    >{ j }</Button>
                ) }
                </Button.Group>
            </React.Fragment>

    return <Segment loading={ busy } key={ i } color={ props.focused ? 'red' : 'green' }>
            { props.focused && <KeyboardEventHandler
                handleKeys={['numeric','x',]}
                onKeyEvent={ handleKeyEvent }
            /> }
            <div ref={ ref }>
            <Divider horizontal>
                <Label color='red'>{ question.nom }</Label>
                <Label>{ question.bareme }pts</Label>
                { (!reponseTypeFormOuverte) &&
                    <Button basic
                        size='mini' compact
                        onClick={ () => {
                            setReponseTypeFormOuverte(false)
                            setReponseTypeFormEdit(null)
                        } }
                    ><Icon name='plus' />rep. type</Button> }
                { (!questionFormOuverte) &&
                    <Button basic
                        size='mini' compact
                        onClick={ () => setQuestionFormOuverte(true) }
                    ><Icon name='pencil' />Éditer</Button> }
                { (question.images64.length > 0)
                        && 
                    <Popup wide 
                        position='top center'
                        open={ false && props.focused }
                        trigger={
                        <Button toggle compact size='tiny' active={ imagesShow }
                            onClick={ (e) => setImagesShow(!imagesShow) } 
                        ><Icon name='picture' /></Button>
                    }
                    >
                        { question.images64.map((image,i) => <SImage fluid key={ i } src={ image } />) }
                    </Popup> }
            </Divider>
            <ReponseTypeForm visible={ reponseTypeFormOuverte } 
                    reponseType={ reponseTypeFormEdit }
                    onCancel={ () => {
                            setReponseTypeFormOuverte(false)
                            setReponseTypeFormEdit(null)
                        } }
                    onSubmit={ handleReponseTypeFormSubmit }
            />
            <QuestionForm visible={ questionFormOuverte } 
                    question={ question }
                    onCancel={ () => setQuestionFormOuverte(false) }
                    onSubmit={ handleQuestionFormSubmit }
            />
            { question.description &&
                <Message>
                    { question.description }
                </Message>
            }
            <Button.Group horizontal='true' fluid>
                <Button basic compact onClick={ (evt) => handleReponse(i, null) } 
                    positive={ !reponse.reponse }>X</Button>
                { Array(question.segments + 1).fill().map((_, j) => 
                    <Button basic compact key={ j } onClick={ (evt) => handleReponse(i, j) } 
                        positive={ (reponse.reponse && reponse.reponse.valeur === j) }
                    >{ j }</Button>
                ) }
            </Button.Group>
            { Object.keys(reponse.reponses_type).length > 0 &&
                <Segment>
                { Object.keys(reponse.reponses_type).map( id =>
                    <React.Fragment key={ id }>
                        <div>{ id }</div>
                    <Checkbox size='tiny'
                        checked={ reponse.reponses_type[id].checked }
                        onChange={ () => 
                            this.toggleReponseType(reponse.reponses_type[id])
                        }
                        label={ reponse.reponses_type[id].nom }
                    />
                    <Button 
                        size='mini' compact basic
                        onClick={ () => {
                            setReponseTypeFormOuverte(true)
                            setReponseTypeFormEdit(reponse.reponses_type[id])
                            }
                        }
                    ><Icon name='pencil'/></Button>
                    </React.Fragment>
                ) }
                </Segment>
            }
            { (imagesShow || props.focused) && question.images64.map((image,i) => <SImage fluid key={ i } src={ image } />) }
            </div>
        </Segment>
}

function CopieCompact () {
    const classe = useStoreState( state => state.classe );
    const sujet = useStoreState( state => state.sujet );
    const copie = useStoreState( state => state.copie );

    const eleve = copie.eleve
    const parties = sujet.parties
    const reponses = copie.reponses

    return <React.Fragment>
       { parties.map( (partie,partieIdx) =>
	       <React.Fragment>
	   <h3>{ partie.nom }</h3>
           <Grid doubling columns={4} key={ partieIdx }>
           {
            partie.questions.map( i =>
                <Grid.Column key={ i } style={{paddingTop : '0',paddingBottom:'0'}}>
                    <Question 
                        compact={ true }
                        focused={ false }
                        imagesShow={ false }
                        question={ sujet.questions[i] } reponse={ copie.reponses[i] }
                        onAnswer={ () => 0 }
                    />
                </Grid.Column>
            )
           }
           </Grid></React.Fragment> ) }
        </React.Fragment>
}

function Copie () {
    const classe = useStoreState( state => state.classe );
    const sujet = useStoreState( state => state.sujet );
    const copie = useStoreState( state => state.copie );
    const compact = useStoreState( state => state.compact );
    const changeAppreciation = useStoreActions( state => state.changeAppreciation );
    const updateCopieShowPartie = useStoreActions( state => state.updateCopieShowPartie );

    const fetchCopie = useStoreActions( state => state.fetchCopie );
    const fetchDepotCopieEleve = useStoreActions( state => state.fetchDepotCopieEleve );

    const eleves = sujet.eleves
    const note = copie.note
    const notes_par_parties = copie.notes_par_parties
    const eleve = copie.eleve

    const parties = sujet.parties
    const reponses = copie.reponses

    const [affichage, setAffichage] = useState('questions')
    const [columns, setColumns] = useState(2)
    const [reponseFocus, setReponseFocus] = useState(0)

    const [imagesShow, setImagesShow] = useState(false)

    const handleChangeAppreciation = (newAppreciation) => {
        changeAppreciation({ sujetId: sujet.id, eleveId: eleve.id, appreciation: newAppreciation })
    }
    const [ width, setWidth ] = useState(0)
    const copieColumnRef = useRef()
    useEffect(() => {
        const handleResize = () => {
            if (affichage !== 'questions')
            {
                setWidth(copieColumnRef.current.offsetWidth)
            }
        }

        if (affichage !== 'questions' && copieColumnRef.current) {
          setWidth(copieColumnRef.current.offsetWidth)
        }

        window.addEventListener("resize", handleResize)

        return () => {
          window.removeEventListener("resize", handleResize)
        }
    }, [copieColumnRef.current, affichage])

    const questionsRef = useRef([]);
    useEffect(() => {
        questionsRef.current = questionsRef.current.slice(0, Object.keys(sujet.questions).length);
    }, [sujet]);

    useEffect(() => {
        setAffichage(copie.images.length > 0 ? 'copie' : 'questions')
    }, [copie.eleve])

    const [questionSelected, setQuestionSelected] = useState(0)
    useEffect(() => {
        setQuestionSelected(0)
    }, [sujet.questions])

    const handleKeyEvent = (key, e) => {
        switch (key) {
            case 'j':
            case 'down':
                return selectNext();
            case 'k':
            case 'up':
                return selectPrevious();
        }
    }

    const scrollToQuestionSelected = () => {
        window.scrollTo({
            behaviour: 'smooth',
            top: questionsRef[questionSelected].current.offsetTop
        })
    }

    const selectPrevious = () => {
        setQuestionSelected(Math.max(0,questionSelected - 1))
    }

    const selectNext = () => {
        setQuestionSelected(Math.min(Object.keys(sujet.questions).length,questionSelected + 1))
    }

    if (compact)
        return <CopieCompact />

    return <React.Fragment>
            <KeyboardEventHandler
                handleKeys={['numeric','x','up','down','left','right']}
                onKeyEvent={ handleKeyEvent }
            />
            <Segment raised>
                <Header as='h1'>
                    Copie { eleve.nom }
                </Header>
                <Header as='h1'>
                    { Math.round(note*100)/100 } points
                </Header>
                <Appreciation onChange={ handleChangeAppreciation } 
                    appreciation={ copie.appreciation } />
            </Segment>
            <Menu compact> 
                <Container>
                    { copie.images.length > 0 &&
                    <Menu.Item>
                        <Label>Affichage</Label>
                        <Button compact circular size='tiny' onClick={ () => { setAffichage('copie') } }>Copie</Button>
                        <Button compact circular size='tiny' onClick={ () => { setAffichage('copie_questions') } }>Copie+Questions</Button>
                        <Button compact circular size='tiny' onClick={ () => { setAffichage('questions') } }>Questions</Button>
                    </Menu.Item> }
                    <Menu.Item>
                        <Label>{ columns } col.</Label>
                        <Button compact circular size='tiny' icon='plus' onClick={ () => { setColumns(columns+1) } }/>
                        <Button compact circular size='tiny' icon='minus' onClick={ () => { setColumns(columns-1) } } />
                    </Menu.Item>
                    <Menu.Item>
                        <Checkbox size='tiny' checked={ imagesShow }
                            onChange={ (e) => setImagesShow(!imagesShow) } 
                            label='Images'
                        />
                    </Menu.Item>
                    <Menu.Item>
                        <Button compact onClick={ () => fetchDepotCopieEleve({ sujetId: sujet.id, eleveId: eleve.id }) }>
                                Éditer la copie
                        </Button>
                    </Menu.Item>
                </Container>
            </Menu>
            <Grid columns={ affichage === 'copie_questions' ? 2 : 1 } >
                { affichage !== 'questions' 
                        && copie.images.length > 0 && 
                            <Grid.Column 
                                width={ affichage === 'copie_questions' ? 11 : 16 }>
                            </Grid.Column> }
                { 
                    <Grid.Column width={ affichage === 'copie_questions' ? 5 : 16 } >
                    { parties.map( (partie,partieIdx) =>
                    <Accordion key={ partie.nom }>
                        <Accordion.Title 
                            active={ partie.visible }
                            onClick={ () => updateCopieShowPartie(partieIdx) }
                    ><Icon name='dropdown'/> Partie { partie.nom } - 
                        { partie.questions.reduce((total, i) => total + sujet.questions[i].bareme / sujet.questions[i].segments * (copie.reponses[i].reponse ? copie.reponses[i].reponse.valeur : 0), 0) } /
                        { partie.questions.reduce((total, i) => total + sujet.questions[i].bareme, 0) }</Accordion.Title>
                        <Accordion.Content active={ partie.visible } >
                        { partie.visible &&
                            <Grid doubling columns={copie.images.length > 0 ? 1 : columns}>
                            { partie.questions.map( i =>
                                <Grid.Column onClick={ () => setQuestionSelected(sujet.questions[i].ordre) } key={ i }>
                                <Question 
                                    compact={ false }
                                    focused={ sujet.questions[i].ordre == questionSelected }
                                    imagesShow={ imagesShow }
                                    question={ sujet.questions[i] } reponse={ copie.reponses[i] }
                                    onAnswer={ selectNext }
                                />
                            </Grid.Column>
                            )}
                        </Grid> }
                        </Accordion.Content>
                    </Accordion>
                    )}
                </Grid.Column>
                }
        </Grid>
     </React.Fragment>
}

export default Copie;
