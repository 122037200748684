import React, { Component } from 'react';
import { Message, Table, Loader, Dimmer, Sticky,Grid, Radio, Card, Progress, Label, Container, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  ResponsiveContainer, ReferenceLine, ReferenceDot, Label as ReLabel, LabelList, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
//import { Provider, connect } from 'react-redux';
//import { bindActionCreators } from 'redux';
import { API } from '../api';

class Remarque extends Component {
    componentDidMount = () => {
        this.renderMath()
    }
    componentDidUpdate = () => {
        this.renderMath()
    }
    renderMath = () => {
        window.MathJax.typeset()
    }
    render = () => {
        return <p>{ this.props.children }</p>
    }
}

class CopieRapport extends Component {
    render() {
        const { histogramme, moyenne, mediane, note_maximum, copies, copie, sujet, classe, classement, questions_stats } = this.props;

        const nbEleves = copies.length
        const escapedNewLineToLineBreakTag = (string) => {
            if (!string) return ''
          return string.split('\n').map((item, index) => {
            return (index === 0) ? item : [<br key={index} />, item]
          })
        }

        return <React.Fragment>
            <Segment textAlign='center'>
                <Header as='h2'>{ copie.nom } - { sujet } - { classe } </Header>
                    <Header as='h3'>Note: { Math.round(10*copie.note_echelle)/10 }/{ note_maximum } (Note brute: { copie.note }pts) Classement: { classement[copie.nom]}/{nbEleves}</Header>
            </Segment>
            <Divider horizontal>Appréciation</Divider>
            <Segment>{ escapedNewLineToLineBreakTag(copie.appreciation) }</Segment>
            <Divider horizontal>Distribution des notes</Divider>
            <div>Moyenne: { Math.round(100*moyenne)/100 }, 
                Médiane: { Math.round(100*mediane)/100 }</div>
            <ResponsiveContainer
                    height={ 300 } width={ 1200 }
        >
                <BarChart
                    margin={{ left: 200, right:200 }}
                    data={ histogramme }
                    fill='#888888'
                >
                    <Bar dataKey='compte' />
                    <XAxis dataKey='note' type='number' domain={[0,note_maximum]} />
                    <ReferenceLine x={moyenne} label={{position:'insideTop', offset: 30, value:'moyenne'}} strokeDasharray="3 3" stroke='black' />
                    <ReferenceLine x={mediane} label={{position:'insideTop', offset: 50, value:'médiane'}} strokeDasharray="1 1" stroke='black' />
                    <ReferenceLine x={copie.note_echelle} label={{position:'insideTop', offset: 0, value:'vous'}} stroke='black' />
                </BarChart>
            </ResponsiveContainer>
                <Divider horizontal>Détail</Divider>
                <Grid centered columns={4}>
                { copie.reponses.reduce( 
                    function(acc,val,i) {
                        var nquestions = Object.keys(questions_stats).length;
                        var batch = Math.ceil(nquestions/4)

                        if (i % batch == 0) {
                            acc.push([])
                        }
                        acc[acc.length-1].push(val)
                        return acc
                    }, 
                    [] ).map( (group,i) =>
                        <Grid.Column key={ i }>
                        <Table size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Q.</Table.HeaderCell>
                                <Table.HeaderCell>Rep</Table.HeaderCell>
                                <Table.HeaderCell>Bar.</Table.HeaderCell>
                                <Table.HeaderCell>Moy.</Table.HeaderCell>
                                <Table.HeaderCell>%</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { group.map( reponse => 
                                <Table.Row key={reponse.question}>
                                    <Table.Cell>{ reponse.question_nom }</Table.Cell>
                                    <Table.Cell>{ reponse.note != null?reponse.note:' ' }</Table.Cell>
                                    <Table.Cell>{ questions_stats[reponse.question].bareme }</Table.Cell>
                                    <Table.Cell>{ questions_stats[reponse.question].moyenne }</Table.Cell>
                                    <Table.Cell>{ questions_stats[reponse.question].traitee } %</Table.Cell>
                                </Table.Row>
                                )
                            }
                        </Table.Body>
                        </Table>
                        </Grid.Column>
                    )
                }
                </Grid>
            { false && <React.Fragments>
                <Divider horizontal>Remarques</Divider>
            <Grid columns={ 3 }>
                { copie.reponses.map( reponse =>
                    (reponse.remarques.length > 0) &&
                        ( <Grid.Column key={ reponse.question }>
                            <div>{ reponse.question }</div>
                            { reponse.remarques.map( (r,i) => <Remarque key={i} >{ r }</Remarque> ) }
                           </Grid.Column> )
                )}
            </Grid> 
            </React.Fragments> }
                
                <p style={{ pageBreakAfter : 'always' }} ></p>
            </React.Fragment>
    }
}

function SujetRapport(props) {
    const fetchRapport = useStoreActions( state => state.fetchRapport )

    React.useEffect(() => {
        const { sujet_id } = props.match.params
        fetchRapport({ sujetId : sujet_id})
    }, [ props.match.params ])

    const { sujet_id } = props.match.params
    const rapport = useStoreState( state => state.rapport )

    if (!rapport) {
        return <Loader />
    }
    const { classement, questions_stats, classe, moyenne, mediane, note_maximum, sujet, copies, histogramme } = rapport;

        return <React.Fragment>
            { copies && copies.map(copie => (
                <CopieRapport key={ copie.nom } copie={ copie } sujet={ sujet } classe={ classe } 
                    histogramme={ histogramme } copies={ copies }
                    moyenne={ moyenne } mediane={ mediane }
		    note_maximum = { note_maximum }
                    classement={ classement } questions_stats={ questions_stats }
                />
            )) }
            </React.Fragment>;
}
 
export default SujetRapport;
