import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Grid, Loader, Dimmer, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'

function SujetForm(props) {
    const [nom,setNom] = useState('')
    const [questions, setQuestions] = useState('')
    const [visible, setVisible] = useState(false)
    const [depotCopie, setDepotCopie] = useState(false)
    const addSujet = useStoreActions(actions => actions.addSujet);

    const handleNouveauSujet = () => {
        addSujet({ nom, questions, visible, depotCopie })
    }

    return  <Segment raised>
            <Form>
                <Form.Field>
                    <label>Nom du sujet</label>
                    <input placeholder='DS' 
                        value={ nom }
                        onChange={ (event) => setNom(event.target.value) }
                    />
                </Form.Field>
                <Form.TextArea 
                    label='Questions' 
                    placeholder='Une question par ligne nom ou nom, bareme, segments'
                        value={ questions }
                        onChange={ (event) => setQuestions(event.target.value) }
                />
                <Form.Checkbox label='Visible' checked={ visible } onChange={ (event) => setVisible(!visible) } />
                <Form.Checkbox label='Depot copies'  checked={ depotCopie } onChange={ (event) => setDepotCopie(!depotCopie) } />
            </Form>
            <Button type='submit' onClick={ handleNouveauSujet } >Ajouter</Button>
                <Button onClick={ e => {
                    props.onClose()
                    e.preventDefault()
                } } 
                >Annuler</Button>
        </Segment>
}

function EleveFormOuverte(props) {
    const eleve = props.eleve

    const u = eleve.utilisateur

    const supprimeEleve = useStoreActions(actions => actions.supprimeEleve);

    const [nom,setNom] = useState(u ? u.last_name : '')
    const [prenom,setPrenom] = useState(u ? u.first_name : '')
    const [username,setUsername] = useState(u ? u.username : '')
    const [password,setPassword] = useState(u ? u.password : '')
    const [email,setEmail] = useState(u ? u.email : '')

    return <Form>
            <Form.Field>
                <label>Prenom</label>
                <Form.Input 
                    placeholder='Prenom'
                    onChange={ (e, { value }) => setPrenom(value) }
                    value={ prenom }
                />
            </Form.Field>
             <Form.Field>
                <label>Username</label>
                <Form.Input 
                    placeholder='Username'
                    onChange={ (e, { value }) => setUsername(value) }
                    value={ username }
                />
            </Form.Field>
            <Form.Field>
                <label>Email</label>
                <Form.Input 
                    placeholder='Email'
                    onChange={ (e, { value }) => setEmail(value) }
                    value={ email }
                />
            </Form.Field>
            <Form.Field>
                <label>Password</label>
                <Form.Input 
                    placeholder='Password'
                    onChange={ (e, { value }) => setPassword(value) }
                    value={ password }
                />
            </Form.Field>
            <Form.Field>
                <label>Nom</label>
                <Form.Input 
                    placeholder='Nom'
                    onChange={ (e, { value }) => setNom(value) }
                    value={ nom }
                />
            </Form.Field>
            <Button type='submit'>Éditer</Button>
            <Button red onClick={ () => supprimeEleve(eleve.id) }>Supprimer</Button>
        </Form>
}

function EleveForm(props) {
    const eleve = props.eleve
    const [formOuverte, setFormOuverte] = useState(false)

    if (formOuverte)
        return <EleveFormOuverte eleve={ eleve } onSubmit={ () => setFormOuverte(false) } />
    else
        return <Button onClick={ () => setFormOuverte(true) }>Éditer</Button>
}


function Classe(props) {
    const classe = useStoreState( state => state.classe );
    const user = useStoreState( state => state.user );
    const fetchSujet = useStoreActions(actions => actions.fetchSujet);
    const addEleves = useStoreActions(actions => actions.addEleves);

    const [formEleves,setFormEleves] = useState('');
    const [sujetFormOuverte,setSujetFormOuverte] = useState(false);

    const handleNouveauxEleves = () => {
        addEleves({ classeId : classe.id, eleves : formEleves })
        setFormEleves('')
    }

    return <React.Fragment>
                <Header as='h3'>Classe { classe.classe }</Header>
                <Divider horizontal>
                    Les Sujets
                </Divider>
                <List size='large'>
                { classe.sujets.map(sujet => 
                    <List.Item key={ sujet.id }>
                        <Button onClick={ () => fetchSujet(sujet.id) } >{ sujet.nom }</Button>
                    </List.Item>
                ) }
                </List>
                { user.username !== 'test_prof' &&
                    <React.Fragment>
                    <Divider horizontal>
                         Ajout d'un sujet
                    </Divider>
                    { sujetFormOuverte ? <SujetForm onClose={ () => setSujetFormOuverte(false) } /> 
                            : <Button onClick={ () => setSujetFormOuverte(true) } >
                            <Icon name='add'/>Nouveau sujet
                            </Button> }
                <Divider horizontal>
                    Élèves de la classe
                </Divider>
                <Grid columns={ 4 }>
                { classe.eleves.map(eleve => 
                    <Grid.Column key={ eleve.id }>
                        { eleve.nom }
                        <EleveForm eleve={ eleve } />
                    </Grid.Column>
                ) }
                </Grid>
                <Form>
                    <Form.TextArea
                        label='Ajouter des élèves'
                        placeholder="Un nom d'élève par ligne"
                        value={ formEleves }
                        onChange={ (event) => setFormEleves(event.target.value) }
                    />
                    <Button type='submit' onClick={ handleNouveauxEleves }>Ajouter</Button>
                </Form>
                </React.Fragment>  }
        </React.Fragment>
}

export default Classe;
