import React, { useState, Component } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Input, Checkbox, Accordion, Table, Loader, Dimmer, Sticky,Grid, Radio, Card, Progress, Label, Container, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default function Sujet() {
    const classe = useStoreState( state => state.classe )
    const sujet = useStoreState( state => state.sujet )
    const fetchCopie = useStoreActions( state => state.fetchCopie )
    const syncSujet = useStoreActions( state => state.syncSujet )
    const setSujetDepotCopies = useStoreActions( state => state.setSujetDepotCopies )
    const setSujetCorrectionVisible = useStoreActions( state => state.setSujetCorrectionVisible )
    const setSujetNoteMaximum = useStoreActions( state => state.setSujetNoteMaximum )
    const setSujetNoteEchelle = useStoreActions( state => state.setSujetNoteEchelle )
    const setSujetNoteEchelleBase = useStoreActions( state => state.setSujetNoteEchelleBase )
    const parties = sujet.parties

    const eleves = sujet.eleves

    const somme_notes = eleves.reduce( (total, eleve) => (eleve.note !== null ? eleve.note : 0) + total, 0 )
    const nombre_notes = eleves.reduce( (total, eleve) => (eleve.note !== null ? 1 : 0) + total, 0 )
    const moyenne = Math.floor(100 * (nombre_notes ? somme_notes/nombre_notes : 0))/100

    const changeQuestion = useStoreActions( state => state.changeQuestion );
    const actionAjouteQuestions = useStoreActions(state => state.ajouteQuestions );
    const actionSupprimeQuestion = useStoreActions( state => state.supprimeQuestion )

    const [ nouvellesQuestions, setNouvellesQuestions ] = useState('')

    const ajouteQuestions = () => {
        actionAjouteQuestions({ sujetId : sujet.id, nouvellesQuestions : nouvellesQuestions })
        setNouvellesQuestions('')
    }

    const modifSegment = (i,delta) => {
        const question = sujet.questions[i]
        changeQuestion({ question, nom : question.nom,
            bareme : question.bareme,
            description : question.description,
            segments : delta + question.segments,
            images64 : question.images64 })
    }

    const modifBareme = (i,delta) => {
        const question = sujet.questions[i]
        changeQuestion({ question, nom : question.nom,
            bareme : delta + question.bareme,
            description : question.description,
            segments : question.segments,
            images64 : question.images64 })
    }

    const supprimeQuestion = (i,delta) => {
        const question = sujet.questions[i]
        actionSupprimeQuestion(question.id)
    }

    return <Container>
            <Header>
                { sujet.nom } - { classe.nom }
            </Header>
            <Divider horizontal>
                <Header as='h4'>
                    Copies
                </Header>
            </Divider>
            <Progress 
                total={ eleves.length }
                value={ nombre_notes }
                progress='ratio'
            />

            <Label>Moyenne: {
                moyenne
            }
            </Label>
		<Form>
		    <Checkbox checked={ sujet.depot_copies } 
			onClick={ () => {
			    setSujetDepotCopies(!sujet.depot_copies)
			    syncSujet()
			} }
			label='Depot Copies' />
		    <Checkbox checked={ sujet.correction_visible } 
			onClick={ () => {
			    setSujetCorrectionVisible(!sujet.correction_visible)
			    syncSujet()
			} }
			label='Correction Visible' />
		    <Input label="Note maximale" value={ sujet.note_maximum }
				onChange={ (e) => setSujetNoteMaximum(e.target.value) } />
		    <Input label="Note x " value={ sujet.note_echelle }
				onChange={ (e) => setSujetNoteEchelle(e.target.value) } />
		    <Input label="Note + " value={ sujet.note_echelle_base }
				onChange={ (e) => setSujetNoteEchelleBase(e.target.value) } />
		</Form>
		<Button onClick={ syncSujet }>Changer</Button>
		<Button>
			<Link to={ "/sujet/" + sujet.id + "/rapport/" }>
			    Rapport
			</Link>
		</Button>
                <Table compact>
                <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Note</Table.HeaderCell>
                <Table.HeaderCell>Note brute</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                { eleves.map(eleve =>
                <Table.Row key={eleve.id}>
                    <Table.Cell>
                        <a href='#' onClick={ () => fetchCopie({ sujetId: sujet.id, eleveId: eleve.id }) } >
                            { eleve.nom }
                            { eleve.copie && eleve.copie.images.length > 0 && <Label circular>{ eleve.copie.images.length }</Label> }
                        </a>
                    </Table.Cell>
                    <Table.Cell>
                    { eleve.note_echelle ? eleve.note_echelle.toString().replace(".",",") : '' }
                    </Table.Cell>
                    <Table.Cell>
                    { eleve.note ? eleve.note.toString().replace(".",",") : '' }
                    </Table.Cell>
                </Table.Row>
                )}
                </Table.Body>
                </Table>
                <Divider horizontal>Barème - { 
                    parties.reduce((total,partie) => 
                        total + partie.questions.reduce((total, questionId) => 
                            total + sujet.questions[questionId].bareme, 0), 
                        0) } </Divider>
            { parties.map( (partie,partieIdx) =>
            <React.Fragment>
                <Divider horizontal>Partie { partie.nom } - { partie.questions.reduce((total, i) => total + sujet.questions[i].bareme, 0) }</Divider>
                <Grid doubling columns={3}>
                { partie.questions.map( i =>
                <Grid.Column key={ i }>
                    <Label style={{minWidth: '100px'}}>{ sujet.questions[i].nom } - { sujet.questions[i].bareme } - { sujet.questions[i].segments }</Label>
                    <Button compact size='tiny' icon='plus' onClick={ () => modifBareme(i,0.25) } />
                    <Button compact size='tiny' icon='minus' onClick={ () => modifBareme(i,-0.25) } />
                    <Button compact color='red' size='tiny' icon='plus' onClick={ () => modifSegment(i,1) } />
                    <Button compact color='red' size='tiny' icon='minus' onClick={ () => modifSegment(i,-1) } />
                    <Button compact size='tiny' icon='trash' onClick={ () => supprimeQuestion(i) } />
                </Grid.Column>
                )}
                </Grid>
            </React.Fragment>
            )}
        
            <Divider horizontal>
                <Header>Ajouter des questions</Header>
            </Divider>
            <Form>
            <Form.TextArea 
                    label='Questions' 
                    placeholder='Une question par ligne nom ou nom, bareme, segments'
                    value={ nouvellesQuestions }
                    onChange={ (event) => setNouvellesQuestions(event.target.value) }
                />
                <Button type='submit' onClick={ ajouteQuestions } >Ajouter</Button>
            </Form>
	
        </Container>
}

