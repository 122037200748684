export const API = 'https://correcteurdata.classeprepa.net';

export default {
    login: (username, password) => {
        return fetch(API + '/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                username: username, 
                password: password 
            })
        }).then(res => res.json())
            .then(data => {
                if (data.success === true) {
                    return data.user
                }

                return null
            })
    },
    fetchClasses: (token) => {
        return fetch(API, {
            headers: {
                'AuthorizationToken': token
            }
        }).then(res => res.json())
    },
    addClasse: (token, nom, lycee) => {
        return fetch(API+'/classe/ajout', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'AuthorizationToken': token
                    },
                    body: JSON.stringify({
                        nom,
                        lycee
                    })
        }).then(response => response.json())
    },
    fetchClasse: (token, classeId) => {
        return fetch(API + '/classe/' + classeId,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
    supprimeEleve: (token, eleveId) => {
        return fetch(API + '/eleve/' + eleveId +'/supprime', {
	      method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
              },
              body: null
	}).then(response => response.json())
    },
    fetchRapport: (token, sujetId) => {
        return fetch(API + '/sujet/rapport/' + sujetId,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },

    addEleves: (token, classeId, eleves) => {
        return fetch(API+'/classe/ajout/eleves', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthorizationToken': token
                },
                body: JSON.stringify({
                    classe: classeId,
                    liste: eleves
                })
        }).then(response => response.json())
    },

    fetchSujet: (token, sujetId) => {
        return fetch(API + '/sujet/' + sujetId,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
    
    fetchCopieEleve: (token, copieId) => {
        return fetch(API + '/copie/' + copieId,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },

    fetchCopie: (token, sujetId, eleveId) => {
        return fetch(API + '/sujet/' + sujetId + '/copie/' + eleveId + '/new',
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
    
    changeAppreciation: (token, sujetId, eleveId, appreciation) => {
        return fetch(API+'/sujet/appreciation', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
              },
              body: JSON.stringify({
                  sujet: sujetId,
                  eleve: eleveId,
                  appreciation: appreciation
              })
            }).then(response => response.json())
    },

    changeReponse: (token, sujet, eleve, question, valeur) => {
        return fetch(API+'/sujet/reponse', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'AuthorizationToken': token
          },
          body: JSON.stringify({ eleve, sujet, question, valeur })
        }).then(response => response.json())
    },

    changeQuestion: (token, { question, nom, bareme, description, segments, images64 }) => {
        return fetch(API+'/sujet/edition/question', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
              },
              body: JSON.stringify({
                  question: question.id,
                  nom: nom,
                  description: description || '',
                  bareme: bareme,
                  segments: segments,
                  images64: images64
              })
            }).then(response => response.json())
            .then(data => {
                return {
                    id: question.id,
                    bareme,
                    description,
                    nom,
                    segments,
                    images64
                }
            })
    },

    changeReponseType: (token, { question, nom, action, texte, reponseType }) => {
        var data = {
              question: question.id,
              nom: nom,
              texte: texte,
              action: action
            }

        if (reponseType !== null)
            data['reponse_type'] = reponseType.id

        return fetch(API+'/sujet/edition/question/reponsetype', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
              },
              body: JSON.stringify(data)
            }).then(response => response.json())
    },

    addSujet: (token, { classeId, nom, questions, visible, depotCopie }) => {
        return fetch(API+'/classe/ajout/sujet', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthorizationToken': token
                },
                body: JSON.stringify({
                    classe: classeId,
                    nom,
                    questions,
                    visible,
                    depotCopie
                })
            }).then(response => response.json())
    },
    
    supprimeQuestion: (token, questionId) => {

        return fetch(API+'/question/' + questionId + '/supprime', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthorizationToken': token
                },
            }).then(response => response.json())
    },

    ajouteQuestions: (token, sujetId, nouvellesQuestions) => {

        return fetch(API+'/sujet/' + sujetId + '/questions/ajoute', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthorizationToken': token
                },
                body: JSON.stringify({
                    questions: nouvellesQuestions,
                })
            }).then(response => response.json())
    },

    addSujet: (token, { classeId, nom, questions, visible, depotCopie }) => {
        return fetch(API+'/classe/ajout/sujet', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'AuthorizationToken': token
                },
                body: JSON.stringify({
                    classe: classeId,
                    nom,
                    questions,
                    visible,
                    depotCopie
                })
            }).then(response => response.json())
    },

    editeCopieImage: (token, { imageId, action }) => {
        return fetch(API+'/copie/image/edite', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
            body: JSON.stringify({
                image_id : imageId,
                action
            })
        }).then(response => response.json())
    },

    addCopieImage: (token, { copieId, image }) => {
        return fetch(API+'/copie/image/ajout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
            body: JSON.stringify({
                copie: copieId,
                image
            })
        }).then(response => response.json())
    },

    fetchDepotCopie: (token, { sujetId }) => {
       return fetch(API+'/sujet/' + sujetId + '/depot', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
        }).then(response => response.json())
    },

    fetchDepotCopieEleve: (token, { sujetId, eleveId }) => {
        return fetch(API+'/sujet/' + sujetId + '/depot/eleve/' + eleveId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
        }).then(response => response.json())
    },

    fetchCopieImage: (token, { imageId }) => {
        return fetch(API+'/copie/image/' + imageId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
        }).then(response => response.json())
    },
    
    annotationCopieImage: (token, { imageId, action, payload }) => {
        return fetch(API+'/copie/image/'+imageId+'/annotation', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
            body: JSON.stringify({
                action, payload
            })
        }).then(response => response.json())
    },
    
    sujetAjuste: (token, sujet) => {
        return fetch(API+'/sujet/'+sujet.id+'/ajuste/new', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthorizationToken': token
            },
            body: JSON.stringify({
                depot_copies : sujet.depot_copies,
                correction_visible : sujet.correction_visible,
		note_maximum: sujet.note_maximum,
		note_echelle: sujet.note_echelle,
		note_echelle_base: sujet.note_echelle_base,
            })
        }).then(response => response.json())
    },

    toggleDossierTraite: (token, dossier) => {
        return fetch(API + '/dossiers/'+dossier.numero+'/traite',
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
    setNoteForcee: (token, dossier, note) => {
        return fetch(API + '/dossiers/'+dossier.numero+'/note/'+note,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },

    updateConfiance: (token, dossier, key, val) => {
        return fetch(API + '/dossiers/'+dossier.numero+'/update/'+key+'/'+val,
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
    fetchDossiers: (token, avec2022) => {
        return fetch(API + '/dossiers/' + (avec2022 ? '2022' : '2021'),
            { headers: { 'AuthorizationToken'  : token }})
            .then(res => res.json())
    },
};
