
export default {
	confianceBonus : {
		'Éliminatoire' : -20,
		'Pas confiant' : -2,
		'Peu confiant' : -1,
		'Neutre' : 0,
		'Plutot confiant' : 1,
		'Très confiant' : 2
	},
	motivationBonus : {
		'Éliminatoire' : -20,
		'Pas confiant' : 0,
		'Peu confiant' : 5,
		'Neutre' : 10,
		'Plutot confiant' : 13,
		'Très confiant' : 15
	},
	comportementBonus : {
		'Éliminatoire' : -20,
		'Pas confiant' : 0,
		'Peu confiant' : 5,
		'Neutre' : 10,
		'Plutot confiant' : 15,
		'Très confiant' : 20
	},
	ratingAvis : {
		'Peu démontrée' : 0,
		'Assez satisfaisante' : 5,
		'Satisfaisante' : 10,
		'Très satisfaisante' : 20
		},
	ratingNiveau : {
		'Mauvais' : 0,
		'Moyen' : 5,
		'Assez bon' : 10,
		'Bon' : 15,
		'Très bon' : 20
		},
	avis : {
		'methode' : 0.2/3,
		'autonomie' : 0.2/3,
		'capacite_investir' : 0.2/3,
		'avis' : 0.8
	},
	coefficients : {
		'math' : 1,
		'mathex' : 0.5,
		'nsi' : 1,
		'pc' : 0.8,
		'nsi_prem' : 0.7,
		'pc_prem' : 0.5,
		'motivation' : 0.6,
		'comportement' : 0.5,
		'niveau_classe' : 0.5,
		'avis' : 0.5,
	}
}

