import React, { createRef, useRef, useEffect, useState, Component } from 'react'
import { Pagination, Tab, Rating, Table, Popup, Placeholder, Menu, Rail, Image as SImage, Accordion, TextArea, Checkbox, Transition, Message, Dropdown, Loader, Dimmer, Sticky,Grid, Radio, Card, Progress, Input, Label, Container, Form, Segment, Divider, Header, Icon, List, Button } from 'semantic-ui-react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import c from '../coefficients'
import createPlotlyComponent from "react-plotly.js/factory"
import _ from 'lodash'
import { API } from '../api'
// import { Document, Page } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

// import Plot from "react-plotly.js";
const Plotly = window.Plotly
const Plot = createPlotlyComponent(Plotly);

const ratingAvis = {
	'Peu démontrée' : 0,
	'Assez satisfaisante' : 1,
	'Satisfaisante' : 2,
	'Très satisfaisante' : 3
	}

const ratingNiveau = {
	'Mauvais' : 0,
	'Moyen' : 1,
	'Assez bon' : 2,
	'Bon' : 3,
	'Très bon' : 4
	}

const ratingConfiance = {
	'Éliminatoire' : 0,
	'Pas confiant' : 1,
	'Peu confiant' : 2,
	'Neutre' : 3,
	'Plutot confiant' : 4,
	'Très confiant' : 5
	}

const confianceRating = {
	0 : 'Éliminatoire',
	1 : 'Pas confiant',
	2 : 'Peu confiant',
	3 : 'Neutre',
	4 : 'Plutot confiant',
	5 : 'Très confiant'
	}


function DossierResume() {
	const dossier = useStoreState(state => state.dossierSel)
	const matieres = ['math','mathex','nsi','pc']

	const totalCoeff = c.coefficients.math
			+ c.coefficients.mathex
			+ c.coefficients.nsi
			+ c.coefficients.pc_prem
			+ c.coefficients.comportement
			+ c.coefficients.niveau_classe
			+ c.coefficients.avis

	const cAvis = c.avis
	var avis = 0

	if (dossier.methode) avis += cAvis.methode * c.ratingAvis[dossier.methode]
	if (dossier.autonomie) avis += cAvis.autonomie * c.ratingAvis[dossier.autonomie]
	if (dossier.capacite_investir) avis += cAvis.capacite_investir * c.ratingAvis[dossier.capacite_investir]
	if (dossier.avis) avis += cAvis.avis * c.ratingAvis[dossier.avis]

	return <Grid>
		<Grid.Row columns={3}>
		<Grid.Column>	
		<Card color='red'>
		  <Card.Header>{ dossier.nom } { dossier.prenom }</Card.Header>
		  <Card.Meta>{ dossier.choixspe }</Card.Meta>
		  <Card.Description>{ dossier.profil }</Card.Description>
	          <Card.Content>
			<List>
				<List.Item>{ dossier.etablissement }</List.Item>
				<List.Item>{ dossier.commune_etablissement } - { dossier.departement_etablissement }</List.Item>
			</List>
		  </Card.Content>
		</Card>
		</Grid.Column>	
		<Grid.Column>	
			<List>
				<List.Item>Avis <Rating rating={ ratingAvis[dossier.avis] } maxRating={ 3 } /></List.Item>
				<List.Item>Niveau classe <Rating rating={ ratingNiveau[dossier.niveau_classe] } maxRating={ 4 } /></List.Item>
				<List.Item>Méthode <Rating rating={ ratingAvis[dossier.methode] } maxRating={ 3 } /></List.Item>
				<List.Item>Autonomie <Rating rating={ ratingAvis[dossier.autonomie] } maxRating={ 3 } /></List.Item>
				<List.Item>Investissement <Rating rating={ ratingAvis[dossier.capacite_investir] } maxRating={ 3 } /></List.Item>
			</List>
		</Grid.Column>	
		<Grid.Column>	
			{ dossier.math_classement &&  <Progress size='tiny' progress='ratio' value={ dossier.math_classement } total={ dossier.math_effectif }>Math</Progress> }
			{ dossier.mathex_classement &&  <Progress size='tiny' progress='ratio' value={ dossier.mathex_classement } total={ dossier.mathex_effectif }>Math Expertes</Progress> }
			{ dossier.nsi_classement &&  <Progress size='tiny' progress='ratio' value={ dossier.nsi_classement } total={ dossier.nsi_effectif }>NSI</Progress> }
			{ dossier.pc_classement &&  <Progress size='tiny' progress='ratio' value={ dossier.pc_classement } total={ dossier.pc_effectif }>PC</Progress> }
		</Grid.Column>	
		</Grid.Row>
		<Grid.Row>
		<Grid.Column>
		<Header>Détail du calcul de la note</Header>
		<Table>
			<Table.Header>
			<Table.Row>
				<Table.HeaderCell>Élément</Table.HeaderCell>
				<Table.HeaderCell>Contribution</Table.HeaderCell>
				<Table.HeaderCell>Coefficient</Table.HeaderCell>
				<Table.HeaderCell>Note</Table.HeaderCell>
				<Table.HeaderCell>Bonus</Table.HeaderCell>
			</Table.Row>
			</Table.Header>
		  	<Table.Body>
				{ matieres.map(mat =>
					dossier[mat+'_note'] &&
						<Table.Row key={ mat }>
						<Table.Cell>{ mat }</Table.Cell>
						<Table.Cell>{ Math.round( (c.coefficients[mat]*(dossier[mat+'_note']+
								c.confianceBonus[dossier['confiance_'+mat]]))*10/totalCoeff)/10}</Table.Cell> 	
						<Table.Cell>{ c.coefficients[mat] }</Table.Cell>
						<Table.Cell>{ dossier[mat+'_note'] }</Table.Cell>
						<Table.Cell>{ c.confianceBonus[dossier['confiance_'+mat]] }</Table.Cell> 
						</Table.Row>) }
				{ dossier.choixspe && dossier.choixspe.includes('(NSI)')
					&& <Table.Row><Table.Cell>nsi 1ere</Table.Cell>
						<Table.Cell>{  Math.round( (c.coefficients.nsi_prem * (
							(dossier.prem_t2_nsi_note)
							?0.5*(dossier.prem_t1_nsi_note+dossier.prem_t2_nsi_note)
							:dossier.prem_t1_nsi_note ))*10/totalCoeff)/10
						}</Table.Cell>
						<Table.Cell>{ c.coefficients.nsi_prem }</Table.Cell>
						<Table.Cell>{ (dossier.prem_t2_nsi_note)
							?0.5*(dossier.prem_t1_nsi_note+dossier.prem_t2_nsi_note)
							:dossier.prem_t1_nsi_note  }</Table.Cell>
						<Table.Cell></Table.Cell></Table.Row> }
				{ dossier.choixspe && dossier.choixspe.includes('(PC)')
					&& <Table.Row><Table.Cell>pc 1ere</Table.Cell>
						<Table.Cell>{  Math.round((c.coefficients.pc_prem * (
							(dossier.prem_t2_pc_note)
							?0.5*(dossier.prem_t1_pc_note+dossier.prem_t2_pc_note)
							:dossier.prem_t1_pc_note ))*10/totalCoeff)/10
						}</Table.Cell>
						<Table.Cell>{ c.coefficients.pc_prem }</Table.Cell>
						<Table.Cell>{ (dossier.prem_t2_pc_note)
							?0.5*(dossier.prem_t1_pc_note+dossier.prem_t2_pc_note)
							:dossier.prem_t1_pc_note  }</Table.Cell>
						<Table.Cell></Table.Cell></Table.Row> }
				{ dossier.choixspe && !dossier.choixspe.includes('NSI')
					&& <Table.Row><Table.Cell>Motiv info</Table.Cell>
						<Table.Cell>{ c.coefficients.motivation * c.motivationBonus[dossier.motivation]/totalCoeff }</Table.Cell>
						<Table.Cell>{ c.coefficients.motivation }</Table.Cell>
						<Table.Cell>{ c.motivationBonus[dossier.motivation] }</Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell></Table.Row> }
				<Table.Row><Table.Cell>Comportement</Table.Cell>
				<Table.Cell>{ c.coefficients.comportement * c.comportementBonus[dossier.comportement]/totalCoeff }</Table.Cell>
				<Table.Cell>{ c.coefficients.comportement }</Table.Cell>
				<Table.Cell>{ c.comportementBonus[dossier.comportement] }</Table.Cell>
						<Table.Cell></Table.Cell></Table.Row> 
				<Table.Row><Table.Cell>Avis</Table.Cell>
		<Table.Cell>{ c.coefficients.avis * avis / totalCoeff }</Table.Cell>
		<Table.Cell>{ c.coefficients.avis }</Table.Cell>
		<Table.Cell>{ avis }</Table.Cell>
						<Table.Cell></Table.Cell></Table.Row> 

			{ dossier.niveau_classe &&
				<Table.Row><Table.Cell>Niveau classe</Table.Cell>
					<Table.Cell>{ c.coefficients.niveau_classe * c.ratingNiveau[dossier.niveau_classe] / totalCoeff }</Table.Cell>
					<Table.Cell>{ c.coefficients.niveau_classe }</Table.Cell>
					<Table.Cell>{ c.ratingNiveau[dossier.niveau_classe] }</Table.Cell>
						<Table.Cell></Table.Cell></Table.Row>  }
		  	</Table.Body>
		</Table>
		</Grid.Column>	
		</Grid.Row>
		</Grid>
}

function DossierPositionnement(props) {
	const dossier = useStoreState(state => state.dossierSel)
	const updateConfiance = useStoreActions(state => state.updateConfiance)
	const matiere = props.matiere
	const ex = props.ex


	return <Grid><Grid.Row columns={ 2 }>
		<Grid.Column width={ 10 } >
			<Plot
				data={[
					{
				      x: [
					      'Prem-1 '+matiere,
					      'Prem-2 '+matiere,
					      //'Prem-3 Math'
					      ]
					        .concat(dossier[matiere+'_classement'] ? ['Term-1 '+matiere, 'Term-2 '+matiere ] : [] )
						.concat((ex && dossier[matiere+'ex_classement']) ? [ 'Term-1 Math Ex', 'Term-2 Math Ex'] : [] ),
				      y: [    
					      dossier['prem_t1_'+matiere+'_moy'], 
					      dossier['prem_t2_'+matiere+'_moy'], 
					      //dossier.prem_t3_math_moy, 
					      dossier['term_t1_'+matiere+'_moy'], 
					      dossier['term_t2_'+matiere+'_moy'],
					 ].concat(ex ? [
					      dossier.term_t1_mathex_moy, 
					      dossier.term_t2_mathex_moy
				         ] : []),
				      mode: 'markers',
				      type: 'scatter',
				      name: 'classe',
				      error_y : {
					      type: 'data',
					      symmetric: false,
					      array: [ 
						      dossier['prem_t1_'+matiere+'_max'] - dossier['prem_t1_'+matiere+'_moy'], 
						      dossier['prem_t2_'+matiere+'_max'] - dossier['prem_t2_'+matiere+'_moy'],
						      //dossier.prem_t3_math_max - dossier.prem_t3_math_moy,
						      dossier['term_t1_'+matiere+'_max'] - dossier['term_t1_'+matiere+'_moy'], 
						      dossier['term_t2_'+matiere+'_max'] - dossier['term_t2_'+matiere+'_moy'] ]
						      .concat(ex ? [dossier.term_t1_mathex_max - dossier.term_t1_mathex_moy, 
						      dossier.term_t2_mathex_max - dossier.term_t2_mathex_moy ] : [] ),
					      arrayminus: [ 
						      dossier['prem_t1_'+matiere+'_moy'] - dossier['prem_t1_'+matiere+'_min'], 
						      dossier['prem_t2_'+matiere+'_moy'] - dossier['prem_t2_'+matiere+'_min'], 
						      //dossier.prem_t3_math_moy - dossier.prem_t3_math_min, 
						      dossier['term_t1_'+matiere+'_moy'] - dossier['term_t1_'+matiere+'_min'], 
						      dossier['term_t2_'+matiere+'_moy'] - dossier['term_t2_'+matiere+'_min'] ]
						      .concat(ex ? [dossier.term_t1_mathex_moy - dossier.term_t1_mathex_min, 
						      dossier.term_t2_mathex_moy - dossier.term_t2_mathex_min ] : [] )
				      },
					},
					{
				      y: [ 
					      dossier['prem_t1_'+matiere+'_note'], 
					      dossier['prem_t2_'+matiere+'_note'], 
					      //dossier.prem_t3_math_note, 
					      dossier['term_t1_'+matiere+'_note'], 
					      dossier['term_t2_'+matiere+'_note'] ]
				    	      .concat(ex ? [dossier.term_t1_mathex_note, dossier.term_t2_mathex_note ] : []),
				      x: [
					      'Prem-1 '+matiere,
					      'Prem-2 '+matiere,
					      //'Prem-3 Math' 
					      ]
					        .concat(dossier[matiere+'_classement'] ? ['Term-1 '+matiere, 'Term-2 '+matiere ] : [] )
						.concat((ex && dossier[matiere+'ex_classement']) ? [ 'Term-1 Math Ex', 'Term-2 Math Ex'] : [] ),
				      mode: 'markers',
				      type: 'scatter',
				      name: 'note',
				      marker: { size: 12, symbol: 'cross' }
				},
			        ]}
			layout={ { yaxis: { 
				range: [0,20]
				} }} 
		      />
		</Grid.Column>
		<Grid.Column width={ 6 }>
			{ dossier[matiere+'_classement'] &&  
			<React.Fragment>
				{ dossier[matiere+'_note'] }/20 Confiance <Rating clearable icon='heart' 
				onRate={ (e, {rating}) => updateConfiance({key:matiere, val:confianceRating[rating]}) }
				rating={ ratingConfiance[dossier['confiance_'+matiere]] } maxRating={ 5 } />
				<br/>
				<Progress progress='ratio' value={ dossier[matiere+'_classement'] } 
					total={ dossier[matiere+'_effectif'] }>{ matiere }</Progress> 
			</React.Fragment>}
			{ ex && dossier[matiere+'ex_classement'] &&  
			<React.Fragment>
				{ dossier['mathex_note'] }/20 Confiance <Rating clearable icon='heart' 
				onRate={ (e, {rating}) => updateConfiance({key:'mathex', val:confianceRating[rating]}) }
				rating={ ratingConfiance[dossier['confiance_mathex']] } maxRating={ 5 } />
				<br/>
				<Progress progress='ratio' value={ dossier[matiere+'ex_classement'] } 
					total={ dossier['mathex_effectif'] }>{ matiere } Expertes</Progress> 
			</React.Fragment> }
			<Message>
			<Message.Header>Consigne de notation</Message.Header>
			La notation par défaut correspond à conserver la note. Si vous pensez que la note est sous-estimée ou sur-estimée, on peut se servir de la notation pour donner un bonus ou un malus en point selon la correspondance suivante :
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.confianceBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.confianceBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.confianceBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.confianceBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.confianceBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.confianceBonus['Éliminatoire'] }</List.Item>
	</List>

			</Message>
		</Grid.Column>

		</Grid.Row></Grid>
}

function FormatProjet(props) {
	const chaine = props.chaine
	const subst = "<b>$&</b>"
	const substn = "<b style='color:red'>$&</b>"
	const substp = "<b style='color:green'>$&</b>"
	if (!chaine) return null
	return <span
		dangerouslySetInnerHTML={{
			__html : chaine
					.replace(/(I|i)nformatique/g, substp)
					.replace(/(P|p)ython/g, substp)
					.replace(/(P|p)rogramm[a-z]*/g, substp)
					.replace(/MP2I/g, subst)
					.replace(/MPSI/g, substn)
		}} />
}


function Projet() {
	const dossier = useStoreState(state => state.dossierSel)
	const updateConfiance = useStoreActions(state => state.updateConfiance)

	return <Grid>
		<Grid.Row>
			<Grid.Column width={ 10 }>
			<Header>Projet de formation</Header>
			<div style={{ whiteSpace: 'pre-line' }}>
				<FormatProjet chaine={ dossier.projet } />
			</div>
			</Grid.Column>
			<Grid.Column width={ 6 }>
				Motivation <Rating icon='heart' 
					clearable
					onRate={ (e, {rating}) => updateConfiance({key:'motivation', val:confianceRating[rating]}) }
					rating={ ratingConfiance[dossier.motivation] } maxRating={ 5 } />
			<Message>
			<Message.Header>Consigne de notation</Message.Header>
			La motivation n'importe que pour ceux qui n'ont pas fait NSI ni en terminale, ni en première.
		Elle correspond à ajouter une fausse note de 1ere NSI selon la correspondance suivante :
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.motivationBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.motivationBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.motivationBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.motivationBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.motivationBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.motivationBonus['Éliminatoire'] }</List.Item>
	</List>

			</Message>
	
			</Grid.Column>
		</Grid.Row>
		</Grid>
}

function FormatBulletin(props) {
	const chaine = props.chaine
	const subst = "<b>$&</b>"
	const substn = "<b style='color:red'>$&</b>"
	const substp = "<b style='color:red'>$&</b>"
	if (!chaine) return null
	return <span
		dangerouslySetInnerHTML={{
			__html : chaine
					.replace(/concentr[a-z]*/g, subst)
					.replace(/sérieu[a-z]*/g, subst)
					.replace(/bavard[a-z]*/g, substn)
					.replace(/dispers[a-z]*/g, substn)
					.replace(/attenti[a-z]*/g, substn)
		}} />
}

function Bulletins() {
	const dossier = useStoreState(state => state.dossierSel)
	const updateConfiance = useStoreActions(state => state.updateConfiance)

	return <Grid>
		<Grid.Row>
			<Grid.Column width={ 5 }>
			<Header>Bulletins 1ere</Header>
			<div style={{ whiteSpace: 'pre-line' }}>
				<FormatBulletin chaine={ dossier.appreciations_term } />
			</div>
			</Grid.Column>
			<Grid.Column width={ 5 }>
			<Header>Bulletins Tle</Header>
			<div style={{ whiteSpace: 'pre-line' }}>
				<FormatBulletin chaine={ dossier.appreciations_prem } />
			</div>
			</Grid.Column>
			<Grid.Column width={ 6 }>
				Comportement <Rating icon='heart' 
					onRate={ (e, {rating}) => updateConfiance({key:'comportement', val:confianceRating[rating]}) }
					clearable
					rating={ ratingConfiance[dossier.comportement] } maxRating={ 5 } />
		<Message>
			<Message.Header>Consigne de notation</Message.Header>
			La note de 3/5 est neutre, on peut l'ajuster en cas de mauvais comportements (manque de sérieux, défauts de concentration...) ou de très bons comportements (élève moteur, participation exceptionnelle, don financier conséquent au professeur d'informatique...). Cela correspond à la notation suivante :
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.comportementBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.comportementBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.comportementBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.comportementBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.comportementBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.comportementBonus['Éliminatoire'] }</List.Item>
	</List>

		</Message>
			</Grid.Column>
		</Grid.Row>
		</Grid>
}

function DossierPDF() {
	const dossier = useStoreState(state => state.dossierSel)
	const user = useStoreState(state => state.user)
	const [ pageNumber, setPageNumber ] = useState(1)
	const [ totalPages, setTotalPages ] = useState(0)

	return <React.Fragment>
		<Pagination 
			onPageChange={ (e, {activePage}) => setPageNumber(activePage) }
			defaultActivePage={ pageNumber } totalPages={ totalPages } />
		<Document
		onLoadSuccess={ ({numPages}) => setTotalPages(numPages) }
		file={{ 
			url:'/api/dossiers/'+dossier.numero+'/pdf',
			httpHeaders: { 
				'AuthorizationToken': user.token,
				}, 
			withCredentials: false
		}}>
			<Page scale={ 1.5 }  pageNumber={ pageNumber } />
		</Document>
		</React.Fragment>
}

function Dossier() {
	const dossier = useStoreState(state => state.dossierSel)
	const toggleDossierTraite = useStoreActions(actions => actions.toggleDossierTraite)
	const setNoteForcee = useStoreActions(actions => actions.setNoteForcee)

	const panes = [
		{ menuItem : 'Résumé', render: () => <Tab.Pane><DossierResume /></Tab.Pane> },
		{ menuItem : 'PDF', render: () => <Tab.Pane><DossierPDF /></Tab.Pane> },
	]
		.concat( dossier.prem_t1_math_note 
		? [ { menuItem: 'Math', render: () => <Tab.Pane><DossierPositionnement matiere='math' ex /></Tab.Pane> } ]
		: [] )
		.concat( (dossier.prem_t1_nsi_note  || dossier.prem_t2_nsi_note)
		? [ { menuItem: 'NSI', render: () => <Tab.Pane><DossierPositionnement matiere='nsi' ex /></Tab.Pane> } ]
		: [] )
		.concat( (dossier.prem_t1_pc_note  || dossier.prem_t2_pc_note)
		? [ { menuItem: 'PC', render: () => <Tab.Pane><DossierPositionnement matiere='pc' ex /></Tab.Pane> } ]
		: [] )
		.concat([
			{ menuItem: 'Lettre', render: () => <Tab.Pane><Projet /></Tab.Pane> },
			{ menuItem: 'Bulletins', render: () => <Tab.Pane><Bulletins /></Tab.Pane> }
		])

	return <React.Fragment>
		{ dossier 
			&& <React.Fragment><Header>
			{ dossier.nom } - classé { dossier.rang }eme - { Math.round(10 * dossier.note)/10 }/20
			</Header> 
	<Checkbox label="Traité" 
		checked={ dossier.traite ? true : false } 
		onChange={ toggleDossierTraite }
	/>
	<Segment>	
	Note forcée : { dossier.note_forcee } <input type='range' min={0} max={20} value={ dossier.note_forcee } onChange={ (e) => setNoteForcee({ note : e.target.value }) } /> (0 pour ignorer)
			</Segment>
	</React.Fragment>
	}
		<Tab panes={ panes }/>
		</React.Fragment>
}

function Dossiers21() {
	const user = useStoreState(state => state.user)
	const dossiers = useStoreState(state => state.dossiers)
	const fetchDossiers = useStoreActions(actions => actions.fetchDossiers)
	const dossierSel = useStoreState(state => state.dossierSel)
	const setDossierSel = useStoreActions(state => state.setDossierSel)
	const toggleDossierTraite = useStoreActions(actions => actions.toggleDossierTraite)
	const [ sortColumn, setSortColumn ] = useState('note')
	const [ sortDirection, setSortDirection ] = useState('descending')
	const [ profilFiltre, setProfilFiltre ] = useState('tous')
	const [ statutFiltre, setStatutFiltre ] = useState('tous')
	const [ assignationFiltre, setAssignationFiltre ] = useState('tous')
	const [ rechercheNom, setRechercheNom ] = useState('')
	const [ rechercheLycee, setRechercheLycee ] = useState('')
	const [ termMath, setTermMath ] = useState(0)
	const [ termMathex, setTermMathex ] = useState(0)
	const [ termNSI, setTermNSI ] = useState(0)
	const [ termPC, setTermPC ] = useState(0)
	const [ premNSI, setPremNSI ] = useState(0)
	const [ premPC, setPremPC ] = useState(0)
	const [ classes, setClasses ] = useState(2 * 509)

	const [ avec2022, setAvec2022 ] = useState(false)

	const [ locaux, setLocaux ] = useState(false)
	const [ filles, setFilles ] = useState(false)
	const [ boursiers, setBoursiers ] = useState(false)
	const [ traites, setTraites ] = useState(0)

	const statut = [
		{ key: 'Tous', text: 'Tous les statuts Parcoursup', value:'tous' },
		{ key: 'ACCEPTE', text: 'Accepté', value:'ACCEPTE' },
		{ key: 'PROPOSE', text: 'Proposé', value:'PROPOSE' },
		{ key: 'REFUS', text: 'Refusé', value:'REFUS' },
		{ key: 'ATTENTE', text: 'List d\'attente', value:'ATTENTE' },
		{ key: 'DESISTEMENT', text: 'Desistement', value:'DESISTEMENT' },
		{ key: '2022', text: '2022', value:'2022' },
		]

	const profil = [
		{ key: 'Tous', text: 'Tous les profils', value:'tous' },
		{ key: 'En Terminale', text: 'En Terminale', value:'En Terminale' },
		{ key: 'Scolarisé dans le supérieur en France', text: 'Réorientation', value:'Scolarisé dans le supérieur en France' },
		{ key: 'Scolarisé dans le secondaire à l\'étranger', text: 'Étranger lycée', value:'Scolarisé dans le secondaire à l\'étranger' },
		{ key: 'Scolarisé dans le supérieur à l\'étranger', text: 'Étranger sup', value:'Scolarisé dans le supérieur à l\'étranger' },
		]

	const assignation = [
		{ key: 'Tous', text: 'Tous les dossiers', value:'tous' },
		{ key: 'Luc', text: 'Dossiers pour Luc', value:'Luc' },
		{ key: 'Marc', text: 'Dossiers pour Marc', value:'Marc' },
		{ key: 'Martial', text: 'Dossiers pour Martial', value:'Martial' },
		{ key: 'Personne', text: 'Non assignés', value:'Personne' },
		]

	const statutColor = {
		ACCEPTE: '#00ff00',
		REFUS: '#ff0000',
		PROPOSE: '#ffcc00',
		ATTENTE: '#999999',
		DESISTEMENT: '#333333'
	}

	useEffect(() => {
		reloadDossier()
	}, [avec2022])

	const sortClick = (n) => {
		if (sortColumn === n) {
			setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending')
		} else {
			setSortColumn(n)
			setSortDirection('ascending')
		}
	}

	const reloadDossier = () => {
		fetchDossiers(avec2022)
	}

	const dossiersFiltres = 
		dossiers ?
		((sortColumn ? _.orderBy(dossiers, [ sortColumn ], [ sortDirection === 'ascending' ? 'asc' : 'desc' ]) : dossiers)
			  	  .filter(dossier => !rechercheNom || dossier.nom.toLowerCase().includes(rechercheNom.toLowerCase()))
			  	  .filter(dossier => !rechercheLycee || (dossier.etablissement &&  dossier.etablissement.toLowerCase().includes(rechercheLycee.toLowerCase())))
			  	  .filter(dossier => !profilFiltre || profilFiltre === 'tous' || dossier.profil === profilFiltre)
			  	  .filter(dossier => !statutFiltre || statutFiltre === 'tous' || dossier.statut === statutFiltre)
			  	  .filter(dossier => !filles || dossier.sexe == 'F')
			  	  .filter(dossier => !boursiers || dossier.boursier == 'Boursier du secondaire')
			  	  .filter(dossier => !locaux || dossier.departement_etablissement == '06' || dossier.departement_etablissement == '83')
			  	  .filter(dossier => termMath != 1 || dossier.math_note)
			  	  .filter(dossier => termMath != 2 || !dossier.math_note)
			  	  .filter(dossier => termMathex != 1 || dossier.mathex_note)
			  	  .filter(dossier => termMathex != 2 || !dossier.mathex_note)
			  	  .filter(dossier => dossier.rang <= classes)
			  	  .filter(dossier => termNSI != 1 || dossier.nsi_note)
			  	  .filter(dossier => termNSI != 2 || !dossier.nsi_note)
			  	  .filter(dossier => termPC != 1 || dossier.pc_note)
			  	  .filter(dossier => termPC != 2 || !dossier.pc_note)
			  	  .filter(dossier => traites != 1 || dossier.traite)
			  	  .filter(dossier => traites != 2 || !dossier.traite)
			  	  .filter(dossier => premNSI != 1 || dossier.prem_t1_nsi_note || dossier.prem_t2_nsi_note)
			  	  .filter(dossier => premNSI != 2 || (!dossier.prem_t1_nsi_note && !dossier.prem_t2_nsi_note))
			  	  .filter(dossier => premPC != 1 || dossier.prem_t1_pc_note || dossier.prem_t2_pc_note)
			  	  .filter(dossier => premPC != 2 || (!dossier.prem_t1_pc_note && !dossier.prem_t2_pc_note))
			  	  .filter(dossier => !assignationFiltre || assignationFiltre === 'tous' || dossier.assignation === assignationFiltre))
		: null

	return <Container>
	   <Dimmer.Dimmable as={ Segment } dimmed={ !dossiers }>
		<Dimmer active={ !dossiers }>
			<Loader>Chargement...</Loader>
		</Dimmer>
		<Header as={ 'h2' }>Classement MP2I</Header>
		{ dossiers && 
			<Header as={ 'h4' }>
			{ dossiersFiltres.length }/{ dossiers.length } Dossiers sélectionnés
			[ { Math.round(1000*dossiersFiltres.length/classes)/10 }% des classés ]
			</Header>
		}
		<Button onClick={ reloadDossier }><Icon name="sync"/></Button>
		<Checkbox label='2022' checked={ avec2022 } onChange={ () => setAvec2022(!avec2022) } />

		<Input icon='search' placeholder='Nom' value={ rechercheNom } onChange={ (e, {value}) => setRechercheNom(value) } />
		<Dropdown options={ profil } selection onChange={ (e, {value}) => setProfilFiltre(value) } value={ profilFiltre } />
		<Dropdown options={ assignation } selection onChange={ (e, {value}) => setAssignationFiltre(value) } value={ assignationFiltre } />
		<Input icon='search' placeholder='Lycée' value={ rechercheLycee } onChange={ (e, {value}) => setRechercheLycee(value) } />
		<br/>
		<Checkbox label='Maths' indeterminate={ termMath === 0 } checked={ termMath === 1 } 
			onChange={ () => setTermMath( (termMath+1)%3 ) } />
		<Checkbox label='Maths Ex' indeterminate={ termMathex === 0 } checked={ termMathex === 1 } 
			onChange={ () => setTermMathex( (termMathex+1)%3 ) } />
		<Checkbox label='NSI' indeterminate={ termNSI === 0 } checked={ termNSI === 1 } 
			onChange={ () => setTermNSI( (termNSI+1)%3 ) } />
		<Checkbox label='PC' indeterminate={ termPC === 0 } checked={ termPC === 1 } 
			onChange={ () => setTermPC( (termPC+1)%3 ) } />
		<Checkbox label='1ere NSI' indeterminate={ premNSI === 0 } checked={ premNSI === 1 } 
			onChange={ () => setPremNSI( (premNSI+1)%3 ) } />
		<Checkbox label='1ere PC' indeterminate={ premPC === 0 } checked={ premPC === 1 } 
			onChange={ () => setPremPC( (premPC+1)%3 ) } />
		<br/>
		<Checkbox label='locaux' checked={ locaux } onChange={ () => setLocaux(!locaux) } />
		<Checkbox label='filles' checked={ filles } onChange={ () => setFilles(!filles) } />
		<Checkbox label='boursiers' checked={ boursiers } onChange={ () => setBoursiers(!boursiers) } />
		<Checkbox label='traites' indeterminate={ traites === 0 } checked={ traites === 1 } 
		 	 onChange={ () => setTraites( (traites+1)%3 ) } />
		
		<Segment>On considère que les classés sont au dessus de { classes }
		<input type='range' min={0} max={dossiers ? dossiers.length : 0} value={classes} onChange={ (e) => setClasses(e.target.value) }/>

		<Dropdown options={ statut } selection onChange={ (e, {value}) => setStatutFiltre(value) } value={ statutFiltre } />
		</Segment>

		<div style={{ height: '300px', overflow: 'auto' }}>
		<Table sortable celled selectable>
		   <Table.Header>
			<Table.Row>
				<Table.HeaderCell></Table.HeaderCell>
				<Table.HeaderCell>Rang</Table.HeaderCell>
				<Table.HeaderCell>Statut</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'note' ? sortDirection : null }
					onClick={ () => sortClick('note') }
				>Note</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'bonus' ? sortDirection : null }
					onClick={ () => sortClick('bonus') }
				>Bonus</Table.HeaderCell>
				<Table.HeaderCell>Numéro</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'nom' ? sortDirection : null }
					onClick={ () => sortClick('nom') }
				>Nom</Table.HeaderCell>
				<Table.HeaderCell>Prénom</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'choixspe' ? sortDirection : null }
					onClick={ () => sortClick('choixspe') }
				>Spé</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'etablissement' ? sortDirection : null }
					onClick={ () => sortClick('etablissement') }
				>Lycée</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'departement_etablissement' ? sortDirection : null }
					onClick={ () => sortClick('departement_etablissement') }
				>Dpt</Table.HeaderCell>
				<Table.HeaderCell
					sorted={ sortColumn === 'assignation' ? sortDirection : null }
					onClick={ () => sortClick('assignation') }
				>Assignation</Table.HeaderCell>
			</Table.Row>
		   </Table.Header>
		  <Table.Body>
		  { dossiers && 
			 	  dossiersFiltres.map(dossier => 
				  <Table.Row warning={ dossierSel && dossier.numero === dossierSel.numero }
					key={ dossier.numero } onClick={ () => setDossierSel(dossier) } >
			<Table.Cell>
	<Checkbox disabled={ !dossierSel || dossier.numero !== dossierSel.numero } 
			checked={ dossier.traite ? true : false }
			  onChange={ toggleDossierTraite }
	/></Table.Cell>
					<Table.Cell>{ dossier.rang }</Table.Cell>
					<Table.Cell style={{ backgroundColor: statutColor[dossier.statut] }}
					 >{ dossier.statut }</Table.Cell>
					<Table.Cell>{ dossier.note && Math.round(10 * dossier.note) / 10 }</Table.Cell>
					<Table.Cell>{ dossier.bonus && Math.round(10 * dossier.bonus) / 10 }</Table.Cell>
					<Table.Cell>{ dossier.numero }</Table.Cell>
					<Table.Cell>{ dossier.nom }</Table.Cell>
					<Table.Cell>{ dossier.prenom }</Table.Cell>
					<Table.Cell>{ dossier.choixspe }</Table.Cell>
					<Table.Cell>{ dossier.etablissement }</Table.Cell>
					<Table.Cell>{ dossier.departement_etablissement }</Table.Cell>
					<Table.Cell>{ dossier.assignation }</Table.Cell>
				  </Table.Row>) }
		  </Table.Body>
		</Table>
		</div>
		{ dossiers && <Plot style={{ width: '100%' }} data={[
						{
			   x: dossiersFiltres.map(dossier => dossier.rang),
			   y: dossiersFiltres.map(dossier => dossier.note),
		           mode: 'markers',
			   type: 'scatter',
			   name: 'dossiers filtrés',
			   opacity: 1,
			   marker: { size: 5, symbol: 'square' }
			},
{
			   x: dossiers.map(dossier => dossier.rang),
			   y: dossiers.map(dossier => dossier.note),
		           mode: 'markers',
			   type: 'scatter',
			   name: 'tous les dossiers',
			   marker: { size: 2, symbol: 'circle' }
			},

			].concat(dossierSel ? [
			{
			   x: [ dossierSel.rang ],
			   y: [ dossierSel.note ],
		           mode: 'markers',
			   type: 'scatter',
			   name: 'dossier sélectionné',
			   marker: { size: 12, symbol: 'cross' }
			}
			]: [])
		}
		/> }
		</Dimmer.Dimmable>
		{ dossierSel && <Dossier/> }
		<Segment>
			<Header>Calcul de la note</Header>
			<Grid>
	<Grid.Row columns={3}>

	<Grid.Column>
			<Header as={ 'h4' }>M-NSI</Header>
			<Plot style={{width:'100%'}}
			data={[{
				values: [ c.coefficients.math, c.coefficients.mathex, c.coefficients.nsi, c.coefficients.pc_prem,
					c.coefficients.avis, c.coefficients.niveau_classe, c.coefficients.comportement
				],
				labels: [ 'Math', 'Math Ex', 'NSI', 'PC 1ere', 'Avis', 'Niveau Classe', 'Comportement'  ],
				type: 'pie', 
				textinfo: 'label+value',
			}]}
			layout={{ showlegend: false }}
			/>
	</Grid.Column>

	<Grid.Column>
			<Header as={ 'h4' }>M-PC-(NSI)</Header>
			<Plot style={{width:'100%'}}
			data={[{
				values: [ c.coefficients.math, c.coefficients.mathex, c.coefficients.pc, c.coefficients.nsi_prem,
					c.coefficients.avis, c.coefficients.niveau_classe, c.coefficients.comportement ],
				labels: [ 'Math', 'Math Ex', 'PC', 'NSI 1ere', 'Avis', 'Niveau Classe', 'Comportement'  ],
				type: 'pie', 
				textinfo: 'label+value',
			}]}
			layout={{ showlegend: false }}
			/>
	</Grid.Column>

	<Grid.Column>
			<Header as={ 'h4' }>M-PC sans NSI</Header>
			<Plot style={{width:'100%'}}
			data={[{
				values: [ c.coefficients.math, c.coefficients.mathex, c.coefficients.pc, c.coefficients.motivation,
					c.coefficients.avis, c.coefficients.niveau_classe, c.coefficients.comportement ],
				labels: [ 'Math', 'Math Ex', 'PC', 'Motivation', 'Avis', 'Niveau Classe', 'Comportement' ],
				type: 'pie', 
				textinfo: 'label+value',
			}]}
			layout={{ showlegend: false }}
			/>
	</Grid.Column>
	</Grid.Row>
	</Grid>
	<Header as={ 'h4' }>
	Correspondances entre les avis et les valeurs numériques
	</Header>
	<Grid>
	<Grid.Row columns={4}>
	<Grid.Column>
	<Header as={ 'h5' }>Pour les avis</Header>
	<List>
		<List.Item>Très satisfaisante : { c.ratingAvis['Très satisfaisante'] }</List.Item>
		<List.Item>Satisfaisante : { c.ratingAvis['Satisfaisante'] }</List.Item>
		<List.Item>Assez satisfaisante : { c.ratingAvis['Assez satisfaisante'] }</List.Item>
		<List.Item>Peu démontrée : { c.ratingAvis['Peu démontrée'] }</List.Item>
	</List>
	<br/>
	La note est { c.avis.avis } Avis + { c.avis.methode } Méthode + { c.avis.autonomie } Autonomie + { c.avis.capacite_investir } Investissement
	<br/>
	<Header as={ 'h5' }>Pour le niveau de classe</Header>
	<List>
		<List.Item>Très bon : { c.ratingNiveau['Très bon'] }</List.Item>
		<List.Item>Bon : { c.ratingNiveau['Bon'] }</List.Item>
		<List.Item>Assez bon : { c.ratingNiveau['Assez bon'] }</List.Item>
		<List.Item>Moyen : { c.ratingNiveau['Moyen'] }</List.Item>
		<List.Item>Mauvais : { c.ratingNiveau['Mauvais'] }</List.Item>
	</List>
	</Grid.Column>
	<Grid.Column>
	<Header as={ 'h5' }>Bonus de confiance envers les notes</Header>
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.confianceBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.confianceBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.confianceBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.confianceBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.confianceBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.confianceBonus['Éliminatoire'] }</List.Item>
	</List>
	</Grid.Column>
	<Grid.Column>
	<Header as={ 'h5' }>Motivation MP2I pour les non NSI</Header>
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.motivationBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.motivationBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.motivationBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.motivationBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.motivationBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.motivationBonus['Éliminatoire'] }</List.Item>
	</List>
	</Grid.Column>

	<Grid.Column>
	<Header as={ 'h5' }>Bonus de comportement</Header>
	<List>
		<List.Item><Rating icon='heart' rating={ 5 } maxRating={ 5 } /> : { c.comportementBonus['Très confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 4 } maxRating={ 5 } /> : { c.comportementBonus['Plutot confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 3 } maxRating={ 5 } /> : { c.comportementBonus['Neutre'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 2 } maxRating={ 5 } /> : { c.comportementBonus['Peu confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 1 } maxRating={ 5 } /> : { c.comportementBonus['Pas confiant'] }</List.Item>
		<List.Item><Rating icon='heart' rating={ 0 } maxRating={ 5 } /> : { c.comportementBonus['Éliminatoire'] }</List.Item>
	</List>
	</Grid.Column>
	</Grid.Row>
	<Grid.Row>
	<Segment>
		<div style={{ height: '300px', overflow: 'auto' }}>
	<List>
		{ dossiers && dossiers.map(dossier => <List.Item>{ dossier.numero + ',' + dossier.rang  }</List.Item>) }
	</List>
	</div>
	</Segment>
	</Grid.Row>
	</Grid>
		</Segment>
	</Container>
}

function Dossiers() {
	const [ dossiers, setDossiers ] = useState('MP2I 2021')

    return <Container>
        <Menu>
            <Menu.Item
                name='MP2I 2022'
                active={ dossiers == 'MP2i 2022' }
                onClick={ () => setDossiers('MP2I 2022') }
            >MP2I 2022</Menu.Item>
            <Menu.Item
                name='MP2I 2021'
                active={ dossiers == 'MP2I 2021' }
                onClick={ () => setDossiers('MP2I 2021') }
            >MP2I 2021</Menu.Item>
        </Menu>
        { dossiers == 'MP2I 2021' && <Dossiers21 /> }
        </Container>
}

export default Dossiers
