import { computed, action, thunk } from 'easy-peasy'
import service from './api'
import c from './coefficients'

export default {
    depotCopie : null,
    compact: true,
    toggleCompact: action((state, val) =>
        state.compact = val?true:false
    ),
    setDepotCopie: action((state, val) => {
        state.depotCopie = val
    }),
    fetchDepotCopieEleve: thunk(async (actions, { sujetId, eleveId }, { getState, getStoreState }) => {
        const user = getState().user
        const depot = await service.fetchDepotCopieEleve(user.token, { sujetId, eleveId });
        if (depot.message === undefined)
            actions.setDepotCopie(depot);
    }),

    fetchDepotCopie: thunk(async (actions, { sujetId }, { getState, getStoreState }) => {
        const user = getState().user
        const depot = await service.fetchDepotCopie(user.token, { sujetId });
        if (depot.message === undefined)
            actions.setDepotCopie(depot);
    }),

    rapport: null,
    setRapport: action((state, rapport) => {
        state.rapport = rapport
    }),
    fetchRapport: thunk(async (actions, { sujetId }, { getState, getStoreState }) => {
        const user = getState().user
        const rapport = await service.fetchRapport(user.token, sujetId)
        if (rapport.message === undefined)
            actions.setRapport(rapport);
    }),

    user: null,
    loginError: false,
    setLoginError: action((state,val) => { state.loginError = val }),
    login: thunk(async (actions, { username, password }) => {
        const user = await service.login(username, password);
        if (user !== null) {
            actions.setUser(user)
	    actions.setLoginError(false)
        } else
	    actions.setLoginError(true)
    }),
    logout: action((state) => {
        state.user = null
        state.depotCopie = null
        state.copie = null
        state.classe = null
        state.sujet = null
        state.classes = {}
    }),
    setUser: action((state, user) => {
        state.user = user
    }),

    classes: {},
    setClasses: action((state, classes) => {
        state.classes = classes.reduce((acc, classe) => {
            acc[classe.id] = classe;
            return acc;
        }, {});
    }),
    fetchClasses: thunk(async (actions, noArg, { getState, getStoreState }) => {
        const user = getState().user
        const classes = await service.fetchClasses(user.token);
        if (classes.message === undefined)
            actions.setClasses(classes);
    }),
    addClasse: thunk(async (actions, { nom, lycee }, { getState, getStoreState }) => {
        const user = getState().user
        const updated = await service.addClasse(user.token, nom, lycee);
        if (updated.message === undefined)
            actions.setClasses(updated);
    }),

    classe: null,
    setClasse: action((state, classe) => {
        state.classe = classe;
    }),
    fetchClasse: thunk(async (actions, classeId, { getState, getStoreState }) => {
        const user = getState().user
        const classe = await service.fetchClasse(user.token, classeId);
        if (classe.message === undefined)
            actions.setClasse(classe);
    }),

    supprimeEleve : thunk(async (actions, eleveId, { getState, getStoreState }) => {
       const user = getState().user
       await service.supprimeEleve(user.token, eleveId)
    }),

    addEleves: thunk(async (actions, {classeId, eleves}, { getState, getStoreState }) => {
        const user = getState().user
        const classe = await service.addEleves(user.token, classeId, eleves);
        if (classe.message === undefined)
            actions.setClasse(classe);
    }),

    sujet: null,
    addSujet: thunk(async (actions, { nom, questions, visible, depotCopie }, { getState, getStoreState }) => {
        const classe = getState().classe
        const user = getState().user
        const updatedClasse = await service.addSujet(user.token, 
            { nom, questions, visible, depotCopie, classeId: classe.id });
        if (updatedClasse.message === undefined)
            actions.setClasse(updatedClasse) 
    }),
    setSujet: action((state, sujet) => {
        state.sujet = sujet;
    }),

    setSujetNoteMaximum: action((state, val) => {
        state.sujet.note_maximum = val
    }),
    setSujetNoteEchelle: action((state, val) => {
        state.sujet.note_echelle = val
    }),
    setSujetNoteEchelleBase: action((state, val) => {
        state.sujet.note_echelle_base = val
    }),
    setSujetDepotCopies: action((state, val) => {
        state.sujet.depot_copies = val
    }),
    setSujetCorrectionVisible: action((state, val) => {
        state.sujet.correction_visible = val
    }),
    syncSujet: thunk(async (actions, _, { getState, getStoreState }) => {
        const user = getState().user
        const sujet = getState().sujet
        await service.sujetAjuste(user.token, sujet)
    }),

    dossierSel: null,
    setDossierSel: action((state, val) => {
	state.dossierSel = val
    }),
    toggleDossierTraiteInData: action((state) => {
	if (!state.dossiers) return
	if (!state.dossierSel) return
	const dossierIdx = state.dossiers.findIndex(d => d.numero === state.dossierSel.numero)
	const val = !state.dossierSel.traite
	state.dossiers[dossierIdx].traite = val
	state.dossierSel.traite = val
    }),
    updateConfianceInData: action((state, {key,val}) => {
	if (!state.dossiers) return
	if (!state.dossierSel) return
	const key2attr = {
            'math' : 'confiance_math',
            'mathex' : 'confiance_mathex',
            'nsi' : 'confiance_nsi',
            'pc' : 'confiance_pc',
            'motivation' : 'motivation',
            'comportement' : 'comportement'
            }
	const dossierIdx = state.dossiers.findIndex(d => d.numero === state.dossierSel.numero)
	state.dossiers[dossierIdx][key2attr[key]] = val
	state.dossierSel[key2attr[key]] = val
    }),
    setNoteForceeInData: action((state,{note}) => {
	const dossierIdx = state.dossiers.findIndex(d => d.numero === state.dossierSel.numero)
	state.dossiers[dossierIdx].note_forcee = note
	state.dossierSel.note_forcee = note
    }),
    setNoteForcee: thunk(async (actions, { note }, { getState, getStoreState }) => {
        const user = getState().user
        const dossier = getState().dossierSel
	service.setNoteForcee(user.token, dossier, note)
		.then(data => {
		if (data.message === undefined)
		{
		   actions.setNoteForceeInData({ note })
		   actions.noteDossiers()
		}
	})
    }),
    toggleDossierTraite: thunk(async (actions, arg, { getState, getStoreState }) => {
        const user = getState().user
        const dossier = getState().dossierSel
	 service.toggleDossierTraite(user.token, dossier)
		.then(data => {
			if (data.message === undefined)
			{
			   actions.toggleDossierTraiteInData()
			}
		})
		
    }),
    updateConfiance: thunk(async (actions, {key,val}, { getState, getStoreState }) => {
        const user = getState().user
        const dossier = getState().dossierSel
        const dossiers = getState().dossiers

	service.updateConfiance(user.token, dossier, key, val)
		.then(data => {
			if (data.message === undefined)
			{
			   actions.updateConfianceInData({key, val}) 
			   actions.noteDossiers()
			}
		})
    }),
    dossiers: null,
    setDossiers: action((state, val) => {
	state.dossiers = val
    }),
    noteDossiers: action((state) => {
	const confianceBonus = c.confianceBonus
	const motivationBonus = c.motivationBonus
	const comportementBonus = c.comportementBonus
	const ratingAvis = c.ratingAvis
	const ratingNiveau = c.ratingNiveau
	const coefficients = c.coefficients
	const cAvis = c.avis

	const matieres = ['math','mathex','nsi','pc']

	state.dossiers.forEach(dossier => {
		dossier.note = 0
		dossier.bonus = 0
		matieres.forEach(mat => {
			if (dossier[mat+'_note']) {
				dossier.bonus += confianceBonus[dossier['confiance_'+mat]] * coefficients[mat]
				dossier.note += coefficients[mat]*dossier[mat+'_note']
			}
		})
		if (dossier.choixspe && dossier.choixspe.includes('(NSI)')) {
			if (dossier.prem_t2_nsi_note)
				dossier.note += coefficients['nsi_prem']*0.5*(dossier.prem_t1_nsi_note+dossier.prem_t2_nsi_note)
			else
				dossier.note += coefficients['nsi_prem']*dossier.prem_t1_nsi_note
		}
		if (dossier.choixspe && dossier.choixspe.includes('(PC)')) {
			if (dossier.prem_t2_pc_note)
				dossier.note += coefficients['pc_prem']*0.5*(dossier.prem_t1_pc_note+dossier.prem_t2_pc_note)
			else
				dossier.note += coefficients['pc_prem']*dossier.prem_t1_pc_note
		}
		if (dossier.choixspe && !dossier.choixspe.includes('NSI'))
			dossier.bonus += coefficients['motivation']*motivationBonus[dossier.motivation]
		
		dossier.bonus += coefficients['comportement']*comportementBonus[dossier.comportement]

		var avis = 0
		if (dossier.methode) avis += cAvis.methode * ratingAvis[dossier.methode]
		if (dossier.autonomie) avis += cAvis.autonomie * ratingAvis[dossier.autonomie]
		if (dossier.capacite_investir) avis += cAvis.capacite_investir * ratingAvis[dossier.capacite_investir]
		if (dossier.avis) avis += cAvis.avis * ratingAvis[dossier.avis]
		dossier.note += coefficients.avis * avis
		dossier.note += dossier.bonus

		if (dossier.niveau_classe) dossier.note += coefficients.niveau_classe * ratingNiveau[dossier.niveau_classe]

		if (dossier.choixspe && !dossier.choixspe.includes('PC')) dossier.note = 0
		if (dossier.choixspe && !dossier.choixspe.includes('Mex') && !dossier.choixspe.includes('M-'))
			dossier.note = 0
		if (dossier.note <= 0 || (dossier.profil !== 'En Terminale' && dossier.profil !== 'En terminale') || !dossier.choixspe) dossier.note = 0

		const totalCoefficients = coefficients.math
			+ coefficients.mathex
			+ coefficients.nsi
			+ coefficients.pc_prem
			+ coefficients.comportement
			+ coefficients.niveau_classe
			+ coefficients.avis

		dossier.note = dossier.note / totalCoefficients
        

		if (dossier.note_forcee > 0)
			dossier.note = dossier.note_forcee
	})
	const notes = state.dossiers.map(dossier => dossier.note).sort((a,b) => b-a)
	state.dossiers.forEach(dossier => {
		dossier.rang = notes.indexOf(dossier.note)+1
	})
	if (state.dossierSel) {
		const dossierIdx = state.dossiers.findIndex(d => d.numero === state.dossierSel.numero)
        if (state.dossiers[dossierIdx]) {
            state.dossierSel.rang = state.dossiers[dossierIdx].rang
            state.dossierSel.note = state.dossiers[dossierIdx].note
            state.dossierSel.bonus = state.dossiers[dossierIdx].bonus
        }
 	}
    }),
    fetchDossiers: thunk(async (actions, avec2022, { getState, getStoreState }) => {
        const user = getState().user
        actions.setDossiers(null);
        const dossiers = await service.fetchDossiers(user.token, avec2022);
        if (dossiers.message === undefined) {
            actions.setDossiers(dossiers)
            actions.noteDossiers(dossiers)
	}
    }),

    fetchCopieEleve: thunk(async (actions, copieId, { getState, getStoreState }) => {
        const user = getState().user
        const copie = await service.fetchCopieEleve(user.token, copieId);
        if (copie.message === undefined)
            actions.setCopie(copie);
    }),

    fetchSujet: thunk(async (actions, sujetId, { getState, getStoreState }) => {
        const user = getState().user
        const sujet = await service.fetchSujet(user.token, sujetId);
        if (sujet.message === undefined)
            actions.setSujet(sujet);
    }),
    ajouteQuestions: thunk(async (actions, { sujetId, nouvellesQuestions }, { getState, getStoreState }) => {
        const user = getState().user
        const sujet = await service.ajouteQuestions(user.token, sujetId, nouvellesQuestions);
        if (sujet.message === undefined)
            actions.setSujet(sujet);
    }),

    setQuestion: action((state, question) => {
        state.sujet.questions[question.id] = question
    }),
    setReponseType: action((state, { question, reponseType }) => {
        state.copie.reponses[question.id].reponses_type[reponseType.id] = reponseType
    }),
   
    copie: null,
    setAppreciation: action((state,appreciation) => {
        state.copie.appreciation = appreciation;
    }),
    setCopie: action((state, copie) => {
        state.copie = copie;
    }),
    fetchCopie: thunk(async (actions, {sujetId, eleveId}, { getState, getStoreState }) => {
        const user = getState().user
        const copie = await service.fetchCopie(user.token, sujetId, eleveId);
        if (copie.message === undefined)
            actions.setCopie(copie);
    }),
    changeAppreciation: thunk(async (actions, {sujetId, eleveId, appreciation}, { getState, getStoreState }) => {
        const copie = getState().copie
        const user = getState().user
        const w = await service.changeAppreciation(user.token, sujetId, eleveId, appreciation);
        if (w.message === undefined)
            actions.setAppreciation(appreciation);
    }),
    updateCopieShowPartie: action((state, partieIdx) => {
        state.sujet.parties[partieIdx].visible = !state.sujet.parties[partieIdx].visible
    }),
    copieSetReponse: action((state,{reponseId, value }) => {
        const question = state.sujet.questions[reponseId]
        const ptParSegment = question.bareme / question.segments
        var noteDelta = value === null ? 0 : (ptParSegment * value)

        if (state.copie.reponses[reponseId].reponse !== null) {
            noteDelta -= ptParSegment * state.copie.reponses[reponseId].reponse.valeur
        }
        if (value === null) {
            state.copie.reponses[reponseId].reponse = null
        } else {
            if (state.copie.reponses[reponseId].reponse === null)
                state.copie.reponses[reponseId].reponse = { valeur: value }
            else
                state.copie.reponses[reponseId].reponse.valeur = value
        }
        state.copie.note += noteDelta
        /*const eleve = state.sujet.eleves.find( (e) => e.id === state.copie.eleve.id )
        eleve.note = state.copie.note*/
    }),
    changeQuestion: thunk(async (actions, {question, nom, bareme, description, segments, images64 }, { getState, getStoreState }) => {
        const user = getState().user
        const updatedQuestion = await service.changeQuestion(user.token, { question, nom, bareme, description, segments, images64 })
        if (updatedQuestion.message === undefined)
            actions.setQuestion(updatedQuestion)
    }),

    supprimeQuestion : thunk(async (actions, questionId, { getState, getStoreState }) => {
       const user = getState().user
       const sujet = await service.supprimeQuestion(user.token, questionId)
        if (sujet.message === undefined)
            actions.setSujet(sujet)
    }),

    updateCopieSetReponse: thunk(async (actions, {reponseId, value}, { getState, getStoreState }) => {
        const copie = getState().copie
        const sujet = getState().sujet
        const user = getState().user
        const w = await service.changeReponse(user.token, sujet.id, copie.eleve.id, reponseId, value);
        if (w.message === undefined)
            actions.copieSetReponse({reponseId, value})
    }),
    changeReponseType: thunk(async (actions, {question, nom, action, texte, reponseType }, { getState, getStoreState }) => {
        const user = getState().user
        const reponseId = await service.changeReponseType(user.token, { question, nom, action, texte, reponseType })
        if (reponseId.message === undefined)
            actions.setReponseType({ question, reponseType : { nom, action, texte, id : reponseId } })
    }),

    setCopieImages: action((state, images) => {
        state.depotCopie.images = images
    }),
    
    moveDownCopieImage: thunk(async (actions, imageId, { getState, getStoreState }) => {
        const user = getState().user
        const depotCopie = getState().depotCopie
        const images = depotCopie.images

        const result = await service.editeCopieImage(user.token, { imageId, action: 'move_down' })

        if (result.success) {
            const i = images.indexOf(imageId);
            if (i < images.length - 1) {
                actions.setCopieImages(
                    [ 
                        ...images.slice(0,i), 
                        images[i+1], images[i], 
                        ...images.slice(i+2) 
                    ]
                )
            }
        }
    
        return result;
    }),

    moveUpCopieImage: thunk(async (actions, imageId, { getState, getStoreState }) => {
        const user = getState().user
        const depotCopie = getState().depotCopie
        const images = depotCopie.images

        const result = await service.editeCopieImage(user.token, { imageId, action: 'move_up' })

        if (result.success) {
            const i = images.indexOf(imageId);
            if (i > 0) {
                actions.setCopieImages(
                    [ 
                        ...images.slice(0,i-1), 
                        images[i], images[i-1], 
                        ...images.slice(i+1) 
                    ]
                )
            }
        }
    
        return result;
    }),


    supprimeCopieImage: thunk(async (actions, imageId, { getState, getStoreState }) => {
        const user = getState().user
        const depotCopie = getState().depotCopie
        const images = depotCopie.images

        const result = await service.editeCopieImage(user.token, { imageId, action: 'suppression' })

        if (result.success) {
            const i = images.indexOf(imageId);
            actions.setCopieImages(
                [ ...images.slice(0,i), ...images.slice(i+1) ]
            )
        }
    
        return result;
    }),

    ajoutCopieImage: thunk(async (actions, { copieId, image }, { getState, getStoreState }) => {
        const user = getState().user
        const depotCopie = getState().depotCopie
        const images = depotCopie.images

        const result = await service.addCopieImage(user.token, { copieId, image })

        if (result.success) {
            actions.setCopieImages([ ...images, result.image ])
        }
    
        return result;
    }),

}
